<template>
  <div v-if="Object.keys(formData).length" class="componentWrapper">
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Voornaam</label>
        <input v-model="formData.userProfile.voornaam" type="text" placeholder="Voornaam" />
        <i :class="{ hidden: formData.userProfile.voornaam?.length < 2 || !formData.userProfile.voornaam }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>Achternaam</label>
        <input type="text" placeholder="Achternaam" v-model="formData.userProfile.achternaam" />
        <i :class="{ hidden: formData.userProfile.achternaam?.length < 2 || !formData.userProfile.achternaam }" class="far fa-check checkmark"></i>
      </div>
    </div>

    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Geboortedatum</label>
        <input type="text" placeholder="01-01-1980" v-model="formData.userProfile.geboortedatum" />
        <i :class="{ hidden: !geboortedatumCheck }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>Telefoon</label>
        <input v-model="formData.userProfile.telefoon" type="tel" placeholder="06-14963847" />
        <i :class="{ hidden: !telefoonCheck }" class="far fa-check checkmark"></i>
      </div>
    </div>
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Hoogstgenoten opleiding</label>
        <select v-model="formData.userProfile.hoogstGenotenOpleiding" value="" placeholder="Hoogstgenoten opleiding">
          <option value="VMBO">VMBO</option>
          <option value="LBO/LTS">LBO/LTS</option>
          <option value="MBO/MTS">MBO/MTS</option>
          <option value="HBO/HTS">HBO/HTS</option>
          <option value="WO">WO</option>
        </select>
        <!-- <input v-model="formData.voornaam" type="text" placeholder="Voornaam" /> -->
        <i :class="{ hidden: !formData.userProfile.hoogstGenotenOpleiding }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>Aantal jaar ervaring</label>
        <select v-model="formData.userProfile.aantalJaarErvaring" placeholder="Aantal jaar ervaring">
          <option value="0-5">0-5</option>
          <option value="6-10">6-10</option>
          <option value="11-15">11+</option>
        </select>
        <i :class="{ hidden: !formData.userProfile.aantalJaarErvaring }" class="far fa-check checkmark"></i>
      </div>
    </div>
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Straatnaam + huisnummer</label>
        <i :class="{ hidden: !formData.userProfile.straat || !formData.userProfile.huisnummer }" class="far fa-check checkmark"></i>
        <div class="oneInput">
          <input class="noStyle street" type="text" placeholder="Straat" v-model="formData.userProfile.straat" />
          <div class="inputWrap">
            <i class="fad fa-house"></i>
            <input class="noStyle lineLeft" type="text" placeholder="13C" v-model="formData.userProfile.huisnummer" />
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="cdt_inputWrapper">
        <label>Postcode + Plaats</label>
        <i :class="{ hidden: !formData.userProfile.plaats || !postcodeCheck }" class="far fa-check checkmark"></i>

        <div class="oneInput">
          <input class="noStyle street" type="text" placeholder="0000 AA" v-model="formData.userProfile.postcode" />
          <div class="inputWrap postcode">
            <i class="fad fa-house"></i>
            <input class="noStyle lineLeft" type="text" placeholder="Plaatsnaam" v-model="formData.userProfile.plaats" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="explanation" class="cdt_note">
      <div class="left">
        <i class="fal fa-info-circle"></i>
        <span
          >Je emailadres <strong>{{ formData.userProfile.email }}</strong> is nog niet aanpasbaar, neem contact op mocht je hem willen wijzigen.</span
        >
      </div>
      <!-- <div @click="$store.commit('toggleLoader')" class="test">
          tsdfest
        </div> -->
      <div style="opacity:0" class="right">
        <i class="fal fa-times-circle"></i>
      </div>
    </div>
    <br v-if="explanation" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      explanation: true,
    }
  },
  computed: {
    formData: {
      get() {
        return this.$store.state.userProfile.formData
      },
      set(updatedFormData) {
        this.$store.commit("updateFormData", {
          ...updatedFormData,
        })
      },
    },
    // ! FORMAT CHECKS
    geboortedatumCheck() {
      if (this.formData.userProfile.geboortedatum) {
        if (this.formData.userProfile.geboortedatum.length === 10) return true
      }
      return false
    },
    telefoonCheck() {
      if (this.formData.userProfile.telefoon) {
        if (this.formData.userProfile.telefoon.length === 10 || this.formData.userProfile.telefoon.length === 11) return true
      }
      return false
    },
    postcodeCheck() {
      if (this.formData.userProfile.postcode) {
        if (this.formData.userProfile.postcode.length === 6 || this.formData.userProfile.postcode.length === 7) return true
      }
      return false
    },
  },
}
</script>

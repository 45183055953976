<template>
  <div class="Component">
    <form @submit.prevent="updateUserProfile()" @input="updateHandler()">
      <!-- <ProfileImageAndAvailability class="tingt" /> -->
      <PersoonlijkeInfo v-if="activeCategory === 'naw'" id="persoonlijkeinfo" />
      <BedrijfsInfo v-if="activeCategory === 'bedrijfsgegevens'" id="bedrijfsinfo" />
      <TekenaarsProfiel v-if="activeCategory === 'werkprofiel'" />
      <Voorkeuren v-if="activeCategory === 'voorkeuren'" />
      <Kwaliteiten v-if="activeCategory === 'kwaliteiten'" />
    </form>
  </div>
</template>

<script>
// import ProfileImageAndAvailability from "@/components/UserProfileComponents/ProfileImageAndAvailability"
import PersoonlijkeInfo from "@/components/UserProfileComponents/PersoonlijkeInfo"
import BedrijfsInfo from "@/components/UserProfileComponents/BedrijfsInfo"
import TekenaarsProfiel from "@/components/UserProfileComponents/TekenaarsProfiel"
import Voorkeuren from "@/components/UserProfileComponents/Voorkeuren"
import Kwaliteiten from "@/components/UserProfileComponents/Kwaliteiten"

export default {
  components: {
    // ProfileImageAndAvailability,
    PersoonlijkeInfo,
    BedrijfsInfo,
    TekenaarsProfiel,
    Voorkeuren,
    Kwaliteiten,
  },
  watch: {
    user: {
      immediate: true,
      handler(newData) {
        if (!newData) return
        this.$store.commit("updateFormData", {
          ...newData,
        })
      },
    },
  },
  computed: {
    activeCategory() {
      return this.$store.state.userProfile.activeCategory
    },
    user() {
      return this.$store.state.user.user
    },
    formData() {
      return this.$store.state.userProfile.formData
    },
  },
  methods: {
    updateHandler() {
      this.$store.commit("changeState", "Modified")
      this.$store.dispatch("debounceUpdateUserProfile")
    },
  },
}
</script>

<style lang="sass">
@import '@/utilities/variables.sass'



.Component
  // padding: 60px 60px
  overflow-y: scroll
  height: 100%
  // background-color: rgba(0, 0, 0, 0.05)

.componentWrapper
  width: 100%
  flex-grow: 1
  border-radius: 20px
  padding: 40px
  background-color: white
  display: flex
  flex-direction: column
  padding-top: 40px
  // padding-bottom: 50px
  margin-bottom: 20px
  padding-bottom: 10px
  border: 2px solid $cdt_background_grey

  .line
    display: flex
    align-items: flex-end

    .cdt_inputWrapper
      overflow: hidden
      display: flex
      flex-direction: column
      width: calc( 50% - 12px )
      padding-bottom: 30px
      transition: .2s
      position: relative

      input
        z-index: 10

      &:focus-within
        label
          color: $cdt_blue

      label
        font-size: 13px
        font-weight: 500
        padding-bottom: 5px
        transition: .1s

      .checkmark
        position: absolute
        transition: .3s .2s cubic-bezier(.53,.11,.59,1.28)
        color: $cdt_green
        right: 4px
        top: 0
        transform: scale(1)

        &.hidden
          // transform: translateX(31px)
          transform: translateY(31px) scale(.6)


    .divider
      min-width: 24px

//
// ?
// ?
// ?
// ? Double inputs

.oneInput
  display: flex
  justify-content: space-between
  background-color: #fff
  width: 100%
  max-width: 100%
  overflow: hidden
  overflow-x: scroll
  .street
    width: 70%
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    border-right-color: rgba(0, 0, 0, 0)
    &:focus
      border-right-color: $cdt_blue

  .inputWrap
    position: relative
    width: 30%
    min-width: 30%
    max-width: 30%
    &.postcode
      width: 50%
      max-width: 50%
      min-width: 50%

    input
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      width: 100%
      padding-left: 42px
      &:focus
        border-color: $cdt_blue

    i
      pointer-events: none
      color: $cdt_blue
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: 15px


// @media only screen and (max-width: 1400px)


@media only screen and (max-width: 1400px)
  .Component
    overflow-y: hidden
    position: relative

  .tingt
    display: none !important

  form
    max-width: 100vw

  input
    height: 60px

  .componentWrapper
    border-radius: 0
    flex-grow: 1
    padding: 20px
    margin-bottom: 30px
    position: relative
    width: 100%
    .line
      width: 100%
      position: relative
      flex-direction: column
      align-items: stretch
      .cdt_inputWrapper
        width: 100%
</style>

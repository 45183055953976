import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/functions"

// ? PRODUCTION
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAtPdz7diequwB0vw_OdGPKHb3Lxv9N-qM",
  authDomain: "cad-tekenaar.firebaseapp.com",
  databaseURL: "https://cad-tekenaar.firebaseio.com",
  projectId: "cad-tekenaar",
  storageBucket: "gs://cad-tekenaar.appspot.com/",
  messagingSenderId: "434432639342",
  appId: "1:434432639342:web:60c11063a87f536e96417f",
  measurementId: "G-86FL5RC3ZX",
})

// ? DEVELOPMENT & TESTING
// const firebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyD7KJrfCAkehHX1wFJsPyvb_dNAvNhPBWw",
//   authDomain: "cdt-dev-94ea4.firebaseapp.com",
//   databaseURL: "https://cdt-dev-94ea4.firebaseio.com",
//   projectId: "cdt-dev-94ea4",
//   storageBucket: "cdt-dev-94ea4.appspot.com",
//   messagingSenderId: "463342726553",
//   appId: "1:463342726553:web:89c07d341bb8916dd73708",
//   measurementId: "G-VXE0ENMJQH",
// })

const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const functions = firebaseApp.functions()

// db.useEmulator("localhost", 5003) // ! only for development
// functions.useEmulator("localhost", 5001) // ! only for development

export { auth, db, storage, functions }

<template>
  <transition name="notice" appear>
    <div v-show="notice" class="notice">
      <div v-if="currentRoute !== '/profiel'" class="button left" style="opacity:0">Bewerk mijn profiel</div>
      <span> Het lijkt erop dat je profiel nog niet volledig is ingevuld</span>
      <div v-if="currentRoute !== '/profiel'" @click="$router.push('/profiel')" class="button">Bewerk mijn profiel</div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    currentRoute() {
      return this.$route.path
    },
    notice() {
      if (!this.userData?.userProfile) return true
      const e = this.userData?.userProfile || {}
      if (e.aantalJaarErvaring && e.aantalJaarZelfstandig && e.achternaam && e.voornaam && e.ambities && e.bedrijfsnaam && e.disciplines.length > 0 && e.geboortedatum && e.hoogstGenotenOpleiding && e.huisnummer && e.iban && e.kvknummer && e.plaats && e.postcode && e.profilePictureURL && e.straat && e.tekenprogrammas.length > 0 && e.telefoon && e.voorkeurTypeOpdrachten && e.website && e.werkgebied.length > 0 && e.werkplek.length > 0 && e.kwaliteiten !== undefined && e.kwaliteiten.length > 0) {
        return false
      } else {
        return true
      }
    },
    userData() {
      return this.$store.getters.userData
    },
    progress() {
      const filledInFields = Object.keys(this.userData.userProfile).length
      const fieldsNeeded = 24
      return `${filledInFields} / ${fieldsNeeded}`
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'

.notice
  font-size: 14px
  font-weight: 600
  background-color: $cdt_red_02
  color: $cdt_red
  max-height: 50px
  padding: 0 18px
  height: 50px
  overflow: hidden
  display: flex
  align-items: center
  justify-content: space-between
  text-align: center
  width: 100%
  span
    flex-grow: 1
    text-align: center
  .button
    cursor: pointer
    background-color: white
    padding: 6px
    border-radius: 4px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)

.notice-enter-active
  transition: 0.6s ease 1.6s
.notice-leave-active
  transition: 0.4s ease
.notice-enter-from,
.notice-leave-to
  max-height: 0px


@media only screen and (max-width: 600px)
  .left
    display: none
  .notice
    flex-direction: column
    max-height: none !important
    padding: 20px
    height: auto
    .button
      margin-top: 10px
</style>

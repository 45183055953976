export const appState = {
  state: {
    open: "",
  },
  mutations: {
    changeOpen(state, payload) {
      state.open = payload
    },
  },
  actions: {},
}

<template>
  <div class="test" @click="copyToClipboard()">
    <slot />
    <div :class="{ succes: succes }" class="tooltip">
      <div class="triangle"></div>
      <span v-if="!succes">Kopiëer</span>
      <span v-else><i class="far fa-check"></i> Gekopiëerd</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopySlot",
  props: ["valueToCopy"],
  data() {
    return {
      succes: false,
    }
  },
  methods: {
    copyToClipboard() {
      var element = document.createElement("textarea")
      element.value = this.valueToCopy

      element.setAttribute("readonly", "")
      element.style = {
        position: "absolute",
        left: "-9999999px",
      }
      document.body.appendChild(element)

      element.select()
      document.execCommand("copy")
      document.body.removeChild(element)

      this.succes = true
      setTimeout(() => {
        this.succes = false
      }, 2000)
    },
  },
}
</script>

<style lang="sass" scoped>

@keyframes wiggle
  0%
    transform: translateX(-50%) translateY(0px) scale(1)
  30%
    transform: translateX(-50%) translateY(0px) scale(1.03)
  60%
    transform: translateX(-50%) translateY(0px) scale(1.1)
  100%
    transform: translateX(-50%) translateY(0px) scale(1)

.test
  white-space: nowrap
  position: relative
  cursor: pointer
  letter-spacing: 0.02em
  &:hover
    .tooltip
      opacity: 1 !important
      transform: translateX(-50%) translateY(0px)
  .tooltip
    .triangle
      position: absolute
      width: 10px
      height: 10px
      transition: .2s
      background-color: black
      top: -5px
      left: 50%
      transform: translateX(-50%) rotate(45deg)
      border-radius: 2px

    font-weight: 800
    pointer-events: none
    opacity: 0
    background-color: black
    color: white
    border-radius: 5px
    padding: 6px 9px
    font-size: 12px
    position: absolute
    bottom: -200%
    left: 50%
    transform: translateX(-50%) translateY(-10px)
    transition: .2s
    &.succes
      .triangle
        background-color: #62E887
      background-color: #62E887
      color: black
      animation: wiggle .3s ease both
</style>

import { db } from "@/firebase"
import { debounce } from "debounce"

import firebase from "firebase/app"
import "firebase/firestore"

import store from "../store"

export const userProfile = {
  state: {
    activeCategory: "naw", // naw, bedrijfsgegevens, werkprofiel,
    state: "",
    formData: {},
  },
  mutations: {
    setActiveCategory(state, payload) {
      state.activeCategory = payload
    },
    changeState(state, payload) {
      state.state = payload
    },
    updateFormData(state, payload) {
      state.formData = {
        ...payload,
        userProfile: {
          disciplines: [],
          tekenprogrammas: [],
          werkgebied: [],
          kwaliteiten: [],
          berekeningen: [],
          werkplek: [],
          voorkeurTypeOpdrachten: [],
          nen2580Werkzaamheden: [],
          aanvullendeApplicaties: [],
          ...payload.userProfile,
        },
      }
    },
  },
  getters: {
    profilePicture(state) {
      if (!state.formData.userProfile || !state.formData.userProfile.profilePictureURL) return `background-image: url("https://www.povertyalliance.org/wp-content/uploads/2019/03/Portrait_Placeholder.png")`
      return `background-image: url("${state.formData.userProfile.profilePictureURL}")`
    },
  },
  actions: {
    debounceUpdateUserProfile: debounce(function({ dispatch }) {
      dispatch("updateUserProfile")
    }, 1500),
    async updateUserProfile({ state, commit }) {
      try {
        await db.doc(`users/${store.state.user.user.uid}`).update({
          ...state.formData,
          lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        })
        commit("changeState", "Synced")
      } catch (error) {
        console.log(error)
      }
    },
    async addBackupUserDataToProfile(context, payload) {
      try {
        await db.doc("temp_users/sAULkkpEQThp68z0GgKUES792sX2").set(payload)
        return "Data succesvol opgeslagen!"
      } catch (error) {
        console.log(error)
        return `Error! ${error}`
      }
    },
    setProfilePictureURL({ state }, url) {
      db.doc(`users/${store.state.user.user.uid}`).update({
        userProfile: {
          ...state.formData.userProfile,
          profilePictureURL: url,
        },
      })
    },
  },
}

<template>
  <div ref="element" class="opdrachtPreviewWrapper" @click="setOpdrachtAsSelectedById()">
    <div class="opdrachtPreview">
      <span>{{ opdrachtNummerFormatted }}</span>
      <div>
        <span class="tag" v-if="isUitnodiging">{{ opdracht.aanvraag.discipline }}</span>
        <span class="tag" v-else>{{ opdracht.opdrachtgever.bedrijfsnaam }}</span>
        <span class="tag archived" v-if="opdrachtIsArchived"><i class="far fa-archive"></i></span>
      </div>
    </div>
    <div class="hoverOut center">
      <span>Bekijk opdracht</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["opdracht", "isUitnodiging"],
  computed: {
    opdrachtNummerFormatted() {
      return `CDT.${new Date(this.opdracht.metadata.createdAt.toDate()).getFullYear()}.${this.opdracht.metadata.opdrachtNummer}`
    },
    opdrachtIsArchived() {
      if (this.opdracht.status[this.opdracht.status.length - 1].event < 10) return true
      return false
    },
  },
  methods: {
    setOpdrachtAsSelectedById() {
      this.$store.commit("setSelectedOpdrachtId", this.opdracht.id)
    },
  },
}
</script>

<style lang="sass" scoped>

.center
  display: flex
  align-items: center
  justify-content: center

.opdrachtPreviewWrapper
  position: relative
  cursor: pointer
  margin-bottom: 10px
  &:hover
    z-index: 2
    .hoverOut
      border-radius: 0 0 10px 10px
      transform: translateY(80%)

.opdrachtPreview
  // border: 2px solid rgba(0, 0, 0, 0.1)
  background-color: #fff
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.06)
  // background-color: #F3F5F9

  padding: 10px
  position: relative
  z-index: 1
  border-radius: 10px
  font-weight: 700
  font-size: 15px
  display: flex
  align-items: center
  justify-content: space-between
  .tag
    font-size: 12px
    padding: 4px 7px
    background-color: #F3F5F9
    color: rgba(0,0,0,0.6)
    border-radius: 10000px
    &.archived
      font-weight: 800
      background-color: #FFE1B2
      margin-left: 5px
      // border: 1px solid rgba(0, 0, 0, 0.15)
      padding-right: 7px
      color: #FF9300

.hoverOut
  background-color: white
  position: absolute
  top: 0px
  right: 0
  bottom: 0px
  left: 0
  border-radius: 10px
  transform: scale(1)
  transition: .3s
</style>

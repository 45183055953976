<template>
  <div v-if="Object.keys(formData).length" class="componentWrapper">
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Bedrijfsnaam</label>
        <input v-model="formData.userProfile.bedrijfsnaam" type="text" placeholder="Bedrijfsnaam" />
        <i v-if="formData.userProfile.bedrijfsnaam" :class="{ hidden: formData.userProfile.bedrijfsnaam.length < 2 }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>KVK-Nummer</label>
        <input type="text" placeholder="KVK-Nummer" v-model="formData.userProfile.kvknummer" />
        <i v-if="formData.userProfile.kvknummer" :class="{ hidden: formData.userProfile.kvknummer.length < 2 }" class="far fa-check checkmark"></i>
      </div>
    </div>
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Iban</label>
        <input v-model="formData.userProfile.iban" placeholder="IBAN HIER" />
        <i v-if="formData.userProfile.iban" :class="{ hidden: formData.userProfile.iban.length < 2 }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>BTW-Nummer</label>
        <input v-model="formData.userProfile.btwnummer" placeholder="Uw btw-nummer" />
        <i v-if="formData.userProfile.btwnummer" :class="{ hidden: formData.userProfile.btwnummer.length < 2 }" class="far fa-check checkmark"></i>
      </div>
    </div>
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Website</label>
        <input type="text" placeholder="www.jouwwebsite.nl" v-model="formData.userProfile.website" />
        <i v-if="formData.userProfile.website" :class="{ hidden: formData.userProfile.website.length < 2 }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>
      <div class="cdt_inputWrapper">
        <label>Aantal jaar zelfstandig</label>
        <select v-model="formData.userProfile.aantalJaarZelfstandig" placeholder="Aantal jaar zelfstandig">
          <option value="0-5">0-5</option>
          <option value="6-10">6-10</option>
          <option value="11-15">11+</option>
        </select>
        <i :class="{ hidden: !formData.userProfile.aantalJaarZelfstandig }" class="far fa-check checkmark"></i>
      </div>
    </div>

    <!-- <div class="line">
      <div class="cdt_inputWrapper">
        <label>Upload een kopie van je kvk-uittreksel</label>
        <input type="text" placeholder="www.jouwwebsite.nl" v-model="formData.website" />
        <i v-if="formData.website" :class="{ hidden: formData.website.length < 2 }" class="far fa-check checkmark"></i>
      </div>
    </div>
    <a href="https://www.kvk.nl/producten-bestellen/bedrijfsproducten-bestellen/uittreksel-handelsregister/" target="_blank">
      <div class="cdt_note">
        <div class="left">
          <i class="fal fa-info-circle"></i>
          <span>Klik hier om je kvk uittreksel te bestellen.</span>
        </div>

        <div class="right">
          <i class="fal fa-times-circle"></i>
        </div>
      </div>
    </a>
    <br /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      explanation: true,
    }
  },
  computed: {
    formData: {
      get() {
        return this.$store.state.userProfile.formData
      },
      set(updatedFormData) {
        this.$store.commit("updateFormData", {
          ...updatedFormData,
        })
      },
    },
  },
}
</script>

<template>
  <div class="Component">
    <div class="paymentWrapper">
      <div class="header">
        <h1>Fee betalen</h1>
        <p>Nadat je een project hebt afgemeld krijg je een factuur met daarop een factuurnummer en de te betalen fee. Gebruik onderstaand formulier om de fee direct te betalen.</p>
        <br />
      </div>
      <div class="content">
        <div class="line">
          <div class="cdt_inputWrapper">
            <label>Factuurnummer</label>
            <input type="text" v-model="factuurNummer" placeholder="Factuurnummer" />
          </div>
          <div class="divider"></div>
          <div class="cdt_inputWrapper">
            <label>Opdrachtnummer</label>
            <input type="text" v-model="opdrachtNummer" placeholder="Opdrachtnummer" />
          </div>
        </div>
        <div class="cdt_inputWrapper">
          <label>Fee bedrag (€) (incl BTW)</label>
          <input type="number" v-model="bedrag" placeholder="Bedrag incl. BTW" />
        </div>
        <br />
      </div>
      <button class="cdt_button" @click="createNewPayment()"><i class="fab fa-ideal"></i> Betaal via iDeal</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"
import { db, functions } from "@/firebase"

export default {
  name: "Fee Betalen",
  data() {
    return {
      factuurNummer: "",
      opdrachtNummer: "",
      bedrag: null,
    }
  },
  computed: {
    bedragFormatted() {
      const bedrag = parseFloat(this.bedrag)
      const update1 = bedrag.toFixed(2).toString()
      return update1
    },
    userData() {
      return this.$store.getters.userData
    },
    userId() {
      return this.userData?.uid
    },
    userName() {
      return `${this.userData?.userProfile?.voornaam || ""} ${this.userData?.userProfile?.achternaam || ""}`
    },
    userEmail() {
      return this.userData?.userProfile?.email
    },
  },
  methods: {
    async createNewPayment() {
      if (!this.bedragFormatted || !this.factuurNummer || !this.opdrachtNummer) return alert("Controleer alle velden. Er lijkt iets niet te kloppen")
      const paymentInfo = {
        price: this.bedragFormatted,
        description: `CDT - ${this.userName} - ${this.opdrachtNummer} - ${this.factuurNummer}`,
      }
      const createPaymentURL = await functions.httpsCallable("createNewPayment")(paymentInfo)
      const paymentID = createPaymentURL.data.id
      const paymentURL = createPaymentURL.data.paymentURL

      db.collection("payments")
        .doc(paymentID)
        .set({
          payed: false,
          userId: this.userId, // ! userId hier!
          userEmail: this.userEmail, // ! user email hier
          userName: this.userName, // ! user voornaam + achternaam hier + leegalternatief
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          factuurNummer: this.factuurNummer, // !factuurnummer hiero
          opdrachtNummer: this.opdrachtNummer, // !opdrachtnummer hiero
        })
        .then(() => {
          window.location.href = paymentURL
        })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

.Component
  padding: 20px
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

  .paymentWrapper
    display: flex
    flex-direction: column
    max-width: 40ch

  .header
    p
      font-size: 13px
      opacity: .6
      // max-width: 45ch
      border-bottom: 1px solid rgba(0, 0, 0, 0.1)
      padding-bottom: 14px

  .divider
    min-width: 24px
  .line
    display: flex
    align-items: flex-end

  .cdt_button
    width: 100%
    display: flex
    align-items: center
    i
      padding-top: 3px
      margin-right: 10px

  .cdt_inputWrapper
    overflow: hidden
    display: flex
    flex-direction: column
    width: 100%
    padding-bottom: 20px
    transition: .2s
    position: relative

    input
      z-index: 10

    &:focus-within
      label
        color: $cdt_blue

    label
      font-size: 13px
      font-weight: 500
      padding-bottom: 5px
      transition: .1s


@media only screen and (max-width: 1400px)
  .Component
    overflow-y: hidden
    position: relative

  .tingt
    display: none !important

  form
    max-width: 100vw

  input
    height: 60px

  .componentWrapper
    border-radius: 0
    flex-grow: 1
    padding: 20px
    margin-bottom: 30px
    position: relative
    width: 100%
    .line
      width: 100%
      position: relative
      flex-direction: column
      align-items: stretch
      .cdt_inputWrapper
        width: 100%

@media only screen and (max-width: 600px)

  .Component
    padding: 20px
    padding-top: 30px
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column


  .line
    width: 100%
    position: relative
    flex-direction: column
    align-items: stretch
    .cdt_inputWrapper
      width: 100%
</style>

<template>
  <div class="explainer">
    <h2>Welkom op de opdrachten pagina</h2>
    <p>Binnenkort kan je op deze pagina al jouw opdrachten beheren. Opdrachten aan jouw toegewezen kan je hier snel accepteren, starten, en factureren.</p>
  </div>

  <div class="cc">
    <div class="columnsWrapper">
      <div class="column" v-for="(column, index) in columns" :key="index">
        <div class="header" :style="column.color">
          <div v-html="column.icon"></div>
          <h3>{{ column.name }}</h3>
        </div>
        <div class="contentWrapper">
          <OpdrachtPreview :is-uitnodiging="column.statusVoorwaarde === 'uitgezet'" :opdracht="opdracht" v-for="(opdracht, index) in getOpdrachtenPerStatus(column.statusVoorwaarde)" :key="index" />
          <div v-if="getOpdrachtenPerStatus(column.statusVoorwaarde)?.length < 3">
            <OpdrachtPreviewEmpty v-for="(opdracht, index) in 3 - getOpdrachtenPerStatus(column.statusVoorwaarde)?.length" :key="index" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="archiveerBalk">
      <div class="line"></div>
      <span v-if="!showGearchiveerdeOpdrachten" @click="showGearchiveerdeOpdrachten = !showGearchiveerdeOpdrachten"><i class="far fa-archive"></i> Toon gearchiveerde opdrachten</span>
      <span v-else @click="showGearchiveerdeOpdrachten = !showGearchiveerdeOpdrachten"><i class="far fa-archive"></i> Verberg gearchiveerde opdrachten</span>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import OpdrachtPreview from "@/blocks/opdrachten/OpdrachtPreview"
import OpdrachtPreviewEmpty from "@/blocks/opdrachten/OpdrachtPreviewEmpty"
export default {
  name: "Opdrachten",
  components: { OpdrachtPreview, OpdrachtPreviewEmpty },
  data() {
    return {
      showGearchiveerdeOpdrachten: false,
      columns: [
        {
          color: `background-color: #1b6dff;`,
          icon: '<i class="far fa-signature"></i>',
          name: "Uitnodigingen",
          statusVoorwaarde: "uitgezet",
        },
        {
          color: `background-color: #072950;`,
          icon: '<i class="far fa-users"></i>',
          name: "Te starten opdrachten",
          statusVoorwaarde: "geaccepteerd",
        },
        {
          color: `background-color: #072950;`,
          icon: `<i class="far fa-piggy-bank"></i>`,
          name: "Lopende opdrachten",
          statusVoorwaarde: "gestart",
        },
      ],
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    hasAccesToOpdrachtenPagina() {
      return true
    },
  },
  methods: {
    getOpdrachtenPerStatus(status) {
      let opdrachtenOfUser
      if (this.showGearchiveerdeOpdrachten) opdrachtenOfUser = [...this.$store.getters.v3opdrachtenOfUser].filter(opdracht => opdracht.status[opdracht.status?.length - 1].event !== 2) || []
      if (!this.showGearchiveerdeOpdrachten) opdrachtenOfUser = [...this.$store.getters.v3opdrachtenOfUser].filter(e => e.status[e.status?.length - 1].event > 9) || []

      const getOpdrachtenByStatusCode = (opdrachten, statusCode) => {
        return opdrachten.filter(opdracht => opdracht.status[opdracht.status.length - 1].event === statusCode)
      }

      if (status === "uitgezet") return getOpdrachtenByStatusCode(opdrachtenOfUser, 30)
      if (status === "geaccepteerd") return [...getOpdrachtenByStatusCode(opdrachtenOfUser, 40), ...getOpdrachtenByStatusCode(opdrachtenOfUser, 50)]
      if (status === "gestart") return getOpdrachtenByStatusCode(opdrachtenOfUser, 60)

      return []
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

.explainer
  user-select: none
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding: 5rem 20px 3rem
  *:not(img)
    opacity: .8
    text-align: center
  p
    opacity: .5
    padding-top: 8px
    max-width: 40ch


.cc
  background-color: $cdt_background_grey
  padding: 40px
  .columnsWrapper
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: space-between
    position: relative
    .column
      background-color: rgba(255, 255, 255, 0.6)
      box-shadow: 0 40px 70px rgba(0, 0, 0, 0.06)
      border-radius: 10px
      position: relative
      width: 32%
      .header
        background-color: black
        padding: 20px
        border-radius: 10px 10px 0 0
        margin-bottom: 20px
        color: white
        display: flex
        flex-direction: column
        font-size: 24px
        align-items: flex-start
        p
          font-size: 16px
      .contentWrapper
        padding: 0 13px 15px

  .archiveerBalk
    margin-top: 40px
    display: flex
    align-items: center
    > *
      transition: .25s !important
    &:hover
      cursor: pointer
      .line, span
        background-color: rgba(0, 0, 0, 0.08)
        color: blue
    .line
      width: 100%
      margin-top: 1px
      height: 1px
      background-color: rgba(0, 0, 0, 0.08)
    span
      border-radius: 10000px
      padding: 6px 10px
      margin: 0 10px
      white-space: nowrap
      display: flex
      align-items: center
      font-size: 13px
      font-weight: 600
      color: rgba(0, 0, 0, 0.6)
      i
        font-size: 12px
        margin-top: 2px
        margin-right: 7px
</style>

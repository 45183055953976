<template>
  <div v-if="paymentMade" class="dark">
    <div class="paymentNotice">
      <i class="fad fa-shield-check"></i>
      <h3>Bedankt {{ userData.userProfile?.voornaam }},<br /><span>We hebben je betaling ontvangen</span></h3>
    </div>
  </div>

  <NotificationModule />

  <AuthLoader />
  <router-view />
  <OpdrachtPopup />

  <!-- <AkkoordPopup v-if="userData && userData.pools?.includes('fVYDecMiy7Vhcjh1cwgP') && !hasAccesToOpdrachtenPagina" /> -->
  <!-- <AkkoordPopup v-if="userData" /> -->
</template>

<script>
import NotificationModule from "@/modules/notifications"

import AuthLoader from "@/components/AuthLoader"
import OpdrachtPopup from "@/blocks/opdrachten/OpdrachtPopup"
// import AkkoordPopup from "@/newComponents2/AkkoordPopup"

export default {
  mounted() {
    this.init()
  },
  data() {
    return {
      paymentMade: false,
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    hasAccesToOpdrachtenPagina() {
      return this.userData?.akkoorden?.pools?.includes("fVYDecMiy7Vhcjh1cwgP")
    },
    selectedOpdracht() {
      return this.$store.getters.selectedOpdracht
    },
  },
  methods: {
    // http://localhost:8080/?message=paymentmade
    init() {
      setTimeout(() => {
        const urlParam = this.$route.query.message
        if (urlParam === "paymentmade") {
          this.paymentMade = true
          this.$router.replace("/")
          setTimeout(() => {
            this.paymentMade = false
          }, 4000)
        }
      }, 1000)
    },
  },
  components: {
    NotificationModule,
    AuthLoader,
    OpdrachtPopup,
    // AkkoordPopup,
  },
}
</script>

<style lang="sass">
.flex-center
  display: flex
  align-items: center
  justify-content: center
</style>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap')
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'

#app
  font-family: 'Manrope', Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: left
  background-color: #000
  color: #000
  font-weight: 500

*
  // text-decoration: none
  font-family: 'Manrope'
  margin: 0
  padding: 0
  box-sizing: border-box

// HIDE SCROLLBARS IN ENTIRE APP
*
  -ms-overflow-style: none  // IE and Edge
  scrollbar-width: none  // Firefox
*::-webkit-scrollbar // Chrome / Safari & Opera
  display: none

// hide input extras by default
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number]
  -moz-appearance: textfield
</style>

<style lang="sass" scoped>

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1


.dark
  animation: fadeIn 0.3s ease-in both
  position: fixed
  z-index: 1000000
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.6)
  display: flex
  align-items: center
  justify-content: center
  .paymentNotice
    background-color: white
    padding: 40px 30px
    border-radius: 20px
    display: flex
    flex-direction: column
    align-items: center
    width: auto
    text-align: center
    h3
      span
        opacity: .4
        font-size: 16px
    i
      margin-bottom: 30px
      color: green
      font-size: 34px




.lockedScreen
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: black
  z-index: 1000000
  padding: 30px
  color: white
  // display: none

.hide
  position: fixed
  bottom: 0
  right: 0
  cursor: pointer
  z-index: 10000000000
  opacity: 0
</style>

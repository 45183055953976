<template>
  <div class="blackFiller" @click="closeForm()"></div>
  <div class="startForm">
    <div class="split">
      <div class="left">
        <div class="header">
          <h3>
            <i class="far fa-piggy-bank" style="margin-right: 4px"></i>
            Factuur Toevoegen
          </h3>
        </div>
        <div class="content">
          <div>
            <label>Upload je eigen factuur</label>
            <div class="qmarkwrapper">
              <div class="questoinmark">
                <div class="ting">Ons systeem maakt gebruik bij het versturen van facturen tot op heden alleen bestanden met het formaat .PNG</div>
                ?
              </div>

              <a style="font-size: 10px; color: grey;" href="https://pdf2png.com/" target="_blank">Converteer PDF naar PNG</a>
            </div>
            <Uploader text="Upload je factuur" :onlyAcceptImages="true" @fileUrl="factuurData.factuurUrl = $event" :storageRefMap="`facturenVanTekenaars`" />
          </div>
          <div>
            <label>Jouw factuurnummer</label>
            <input v-model="factuurData.factuurNummerTekenaar" placeholder="Jouw eigen factuurnummer" type="text" />
          </div>
          <div>
            <label>Referentie / inkoopnummer opdrachtgever</label>
            <input v-model="factuurData.referentieNummer" placeholder="Van opdrachtgever" type="text" />
          </div>
          <div style="position: relative;">
            <label>Totaalbedrag op jouw factuur excl. btw</label>
            <input v-model.number="bedragExclusiefBtwTerControle" placeholder="€100,00" type="number" />
            <div v-if="bedragExclusiefBtwTerControle" class="noticeAlert">
              <span style="color: green; font-size:16px;"><i class="fad fa-check-circle"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <h3>Samenvatting</h3>
        </div>
        <div class="content">
          <div>
            <p>
              <span>1 <span class="linee"></span></span>Maak je eigen factuur
            </p>
            <p>
              <span>2 <span class="linee"></span></span>Upload je factuur
            </p>
            <p>
              <span>3 <span class="linee"></span></span>Neem je gegevens over
            </p>
            <p>
              <span>4 <span class="linee"></span></span>Controleer de bedragen
            </p>
            <p>
              <span>5 <span class="linee"></span></span>Druk op versturen
            </p>
          </div>
          <div>
            <ul>
              <br />
              <li>
                <span>Bedrag excl. btw:</span>
                <div>
                  <span>€</span><span>{{ prijsInfo.bedragExclBtw.toFixed(2) }}</span>
                </div>
              </li>
              <li>
                <span>Bedrag incl. btw:</span>
                <div>
                  <span>€</span><span>{{ prijsInfo.bedragInclBtw.toFixed(2) }}</span>
                </div>
              </li>
              <br />
              <li>
                <span>Commissie excl. btw:</span>
                <div>
                  <span>€</span><span>{{ prijsInfo.feeExclBtw.toFixed(2) }}</span>
                </div>
              </li>
              <li>
                <span>Commissie Incl. btw:</span>
                <div>
                  <span>€</span><span>{{ prijsInfo.feeInclBtw.toFixed(2) }}</span>
                </div>
              </li>
            </ul>
            <!--  -->
            <br />
            <br />
            <br />

            <button :class="{ clickable: formCompleted }" @click="submit()"><i class="far fa-file-invoice"></i> Verstuur naar opdrachtgever</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from "../Uploader"

export default {
  props: ["isPartnerOpdracht"],

  data() {
    return {
      werkzaamheden: [
        {
          prijs: null,
          aantal: null,
          opmerking: "",
        },
      ],
      bedragExclusiefBtwTerControle: null,
      factuurData: {
        factuurUrl: "",
        referentieNummer: "",
        factuurNummerTekenaar: "",
      },
    }
  },
  components: { Uploader },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    adres() {
      const e = this.userData.userProfile
      if (!e.straat || !e.huisnummer || !e.plaats || !e.postcode) return false
      return `${e.straat} ${e.huisnummer}, ${e.plaats}, ${e.postcode}`
    },
    naamTekenaar() {
      const e = this.userData.userProfile
      if (!e.voornaam || !e.achternaam) return false
      return `${e.voornaam} ${e.achternaam}`
    },
    emailTekenaar() {
      const e = this.userData.userProfile
      return e.email
    },
    selectedOpdracht() {
      return this.$store.getters.selectedOpdracht
    },
    formCompleted() {
      const e = this.factuurData
      const adres = this.adres
      const naamTekenaar = this.naamTekenaar
      if (!this.factuurData.referentieNummer) return false
      if (!this.factuurData.factuurNummerTekenaar) return false
      // if (!this.isWerkzaamhedenTotaalEqualToCheckTotal) return false
      if (!adres || !naamTekenaar) return false
      if (!this.bedragExclusiefBtwTerControle || !e.factuurUrl) return false
      return true
    },
    prijsInfo() {
      if (this.bedragExclusiefBtwTerControle === null || this.bedragExclusiefBtwTerControle === "")
        return {
          bedragExclBtw: 0,
          bedragInclBtw: 0,
          feeExclBtw: 0,
          feeInclBtw: 0,
        }
      return {
        bedragExclBtw: parseFloat(this.bedragExclusiefBtwTerControle),
        bedragInclBtw: parseFloat(this.bedragExclusiefBtwTerControle) * 1.21,
        feeExclBtw: parseFloat(this.bedragExclusiefBtwTerControle) * 0.15,
        feeInclBtw: parseFloat(this.bedragExclusiefBtwTerControle) * 0.15 * 1.21,
      }
    },
  },
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      if (!confirm("Weet je zeker dat je deze factuur wilt versturen naar de opdrachtgever?")) return
      const e = this.factuurData
      const adres = this.adres
      const naamTekenaar = this.naamTekenaar
      if (!adres || !naamTekenaar || !this.emailTekenaar) return alert("Het lijkt erop dat je geen compleet profiel hebt...")
      this.$emit("submit", {
        ...e,
        kvkTekenaar: this.userData.userProfile.kvknummer || "",
        telefoonTekenaar: this.userData.userProfile.telefoon || "",
        werkzaamheden: this.werkzaamheden,
        adresTekenaar: adres,
        adresOpdrachtgever: this.selectedOpdracht.opdrachtgever.adres,
        naamTekenaar: naamTekenaar,
        emailTekenaar: this.emailTekenaar, // !
        factuurNummer: "", // ! opdrachtnummer + id van tekenaar + index van aantal facturen?
        bedragExclBtw: parseFloat(this.bedragExclusiefBtwTerControle).toFixed(2),
        bedragInclBtw: (parseFloat(this.bedragExclusiefBtwTerControle) * 1.21).toFixed(2),
        feeExclBtw: (parseFloat(this.bedragExclusiefBtwTerControle) * 0.15).toFixed(2),
        feeInclBtw: (parseFloat(this.bedragExclusiefBtwTerControle) * 0.15 * 1.21).toFixed(2),
      })
      this.closeForm()
    },
  },
}
</script>

<style lang="sass" scoped>

.qmarkwrapper
  display: flex
  align-items: center
  position: relative
  justify-content: space-between
  flex-direction: row-reverse
  padding-bottom: 2rem
  margin-top: -5px

  .questoinmark
    // transform: translateY(-10px)
    .ting
      pointer-events: none
      padding: 6px
      top: 50%
      border-radius: 5px
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4)
      left: 0
      width: 100%
      font-size: 13px
      position: absolute
      background-color: black
      color: white
      opacity: 0
    &:hover
      .ting
        opacity: 1
    margin-right: 6px
    font-size: 12px
    width: 1rem
    height: 1rem
    border: 1px solid rgb(27, 109, 255)
    color: rgb(27, 109, 255)
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center


.content
  flex-grow: 1
  justify-content: space-around
  p
    transform: translateX(54px)
    padding: 0 0 6px
    min-width: 30ch
    opacity: .5
    display: flex
    align-items: center


    $size: 12px

    > span
      // transform: scale(0.8)
      margin-right: 20px
      border-radius: 100%
      // color: white
      color: blue
      font-size: 12px
      font-weight: 600
      width: $size
      height: $size
      background-color: blue
      display: flex
      align-items: center
      justify-content: center
      position: relative


      .linee
        display: flex
        position: absolute
        // top: $size
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        height: 49px
        width: 1px
        z-index: 3
        // background-color: blue
        background: repeating-linear-gradient(to bottom,transparent 0 4px,blue 4px 8px) 80%/2px 100% no-repeat
        // &:last-child
          // display: none

ul
  list-style: none
  li
    white-space: nowrap
    display: flex
    align-items: center
    justify-content: space-between
    > div
      display: flex
      align-items: center
      span
        &:nth-child(2)
          text-align: right
          min-width: 80px


label
  white-space: nowrap

.noticeAlert
  position: absolute
  z-index: 2
  top: 32px
  right: 18px
  padding: 14px
  // opacity: .5
  // border-radius: 4px
  // margin-top: 10px
  // background-color: rgba(245, 10, 2, 0.12)
  span
    white-space: nowrap
    text-align: center
    font-size: 12px
    line-height: 12px
    color: red


.lineWrapper
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 10px

.line
  border: 1px solid rgba(0, 0, 0, 0.2)
  display: flex
  border-radius: 7px
  overflow: hidden
  &.add
    margin-top: 10px
    padding: 10px
    display: flex
    align-items: center
    justify-content: center
    background-color: rgba(0, 0, 0, 0.04)
    font-size: 14px
    color: blue
    cursor: pointer
    transition: .2s
    &:hover
      background-color: rgba(0, 0, 0, 0.08)

  .col
    height: auto
    border-radius: 0
    border: none
    padding: 14px
    width: 100%
    &:nth-child(1), &:nth-child(2)
      max-width: 70px
      border-right: 1px solid rgba(0, 0, 0, 0.2)

.removeButton
  margin-left: 10px
  background-color: rgba(0, 0, 0, 0.1)
  border-radius: 100%
  width: 30px
  height: 30px
  display: flex
  align-items: center
  justify-content: center
  font-size: 13px
  color: black
  cursor: pointer
  &:hover
    background-color: black
    color: red


.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.7)


.startForm
  position: fixed
  z-index: 10002
  // background-color: #fff
  // box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 0px !important

  display: flex
  flex-direction: column
  max-height: 95vh
  overflow-y: scroll

  .split
    display: flex
    box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
    > div
      width: 50%
      max-width: 50%
      min-width: 50%
    .left
      display: flex
      flex-direction: column
      border-radius: 14px
      box-shadow: 20px 20px 240px rgba(0, 0, 0, 0.3)
      z-index: 1
      overflow: hidden
      background-color: #fff
    .right
      display: flex
      background-color: #f1f1f1
      flex-direction: column
      // background-color: rgba(0, 0, 0, 0.04)
      background-color: #fff
      border-radius: 0 14px 14px 0
      // border-left: 1px solid rgba(0, 0, 0, 0.1)
      transform: scaleY(0.955)
      .header
        border-bottom: none
        padding: 30px 20px
        background-color: rgba(0, 0, 0, 0)
        color: black
        flex-direction: column
        align-items: flex
        span
          font-size: 14px
          // padding-top: 6px
          opacity: .5


  .header
    // border-radius:
    display: flex
    padding: 20px
    justify-content: space-between
    align-items: center
    background-color: #072950
    color: white
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  .content
    padding: 10px 0
    display: flex
    flex-direction: column
    > div
      // border-bottom: 1px solid rgba(0,0,0,0.1)
      padding: 10px 20px
      margin-bottom: 10px
      display: flex
      flex-direction: column
      // padding-top: 15px
      label
        font-size: 14px
        font-weight: 600
        padding-bottom: 6px
      &.werkzaamheden
        padding: 20px
        // background-color: rgba(0, 0, 0, 0.03)

  button
    // background-color: blue
    background-color: rgba(0, 0, 0, 0)
    // background-color: rgba(0, 0, 0, 0.4)
    border: none
    border: 2px solid rgba(0, 0, 0, 0.1)
    color: white
    color: rgba(0, 0, 0, 0.3)
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    // opacity: 0.4
    pointer-events: none
    transition: .3s

    @keyframes pop
      0%
        transform: scale(1)
      30%
        transform: scale(1.04)

      100%
        transform: scale(1)

    &.clickable
      animation: pop 0.6s both
      background-color: blue
      color: white
      border: none
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

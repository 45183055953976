<template>
  <div class="NieuweOpdrachtenComponent">
    <div class="header">
      <div class="headerTop">
        <h2>Nieuwe Opdrachten</h2>
      </div>
      <div @click="toggleViewMode()" class="filterWrapper">
        <span>Filter beschikbare opdrachten</span>
        <div class="toggleFilter" :class="{ toggleFilterActive: viewMode === 'filtered' }">
          <div :class="{ toggleActive: viewMode === 'filtered' }" class="toggle"></div>
        </div>
      </div>
    </div>
    <div class="opdrachtenContainer">
      <!-- New opdrachten -->
      <div v-for="opdr in filteredV3Opdrachten" :key="opdr.id" class="opdrachtWrapper" :class="{ open: opdr.open }" @click="toggleOpdrachtOpenState($event, opdr)">
        <div class="opdrachtTop">
          <div>
            <h4>CDT.{{ new Date(opdr.metadata.createdAt.toDate()).getFullYear() }}.{{ opdr.metadata.opdrachtNummer }}</h4>
            <span class="disciplineBol">{{ opdr.disciplines }}</span>
          </div>
          <div class="flex1">
            <span class="datumFix">{{ new Date(opdr.metadata.createdAt.toDate()).toLocaleDateString() }}</span>
            <i v-if="isV3OpdrachtOpen(opdr)" style="transform:translateX(4px);" :class="{ open: isV3OpdrachtOpen(opdr) }" class="far fa-lock-open"></i>
            <i style="padding-top:5px" v-if="!isV3OpdrachtOpen(opdr)" :class="{ open: isV3OpdrachtOpen(opdr) }" class="far fa-lock"></i>
          </div>
        </div>
        <div class="opdrachtBottom">
          <h4>Beschrijving</h4>
          <span class="block_description">{{ opdr.aanvraag.omschrijving }}</span>
          <br />
          <h4>Discipline</h4>
          <span class="block_description">{{ opdr.aanvraag.discipline }}</span>
          <br />
          <h4>Locatie</h4>
          <span class="block_description">{{ opdr.opdrachtgever.adres.provincie }}</span>
          <button v-show="isV3OpdrachtOpen(opdr) && !opdr.afhandeling?.interestedUsers?.find(e => e.userId === userData.uid) && !notice" class="opdr_button" @click="pushUserToV3OpdrachtInteresse(opdr.id)">
            Ik heb interesse
          </button>
          <span style="background-color: red; color: white; padding: 6px; border-radius: 5px; margin-top: 10px;" v-if="isV3OpdrachtOpen(opdr) && !opdr.afhandeling?.interestedUsers?.find(e => e.userId === userData.uid) && notice">Maak je profiel compleet om te kunnen reageren op opdrachten</span>
          <span class="interesse" v-if="isV3OpdrachtOpen(opdr) && opdr.afhandeling?.interestedUsers?.find(e => e.userId === userData.uid)">Je hebt interesse in deze opdracht</span>
        </div>
      </div>

      <!-- old opdrachten -->
      <div v-for="opdr in filteredOpdrachten" :key="opdr.id" class="opdrachtWrapper" :class="{ open: opdr.open }" @click="toggleOpdrachtOpenState($event, opdr)">
        <div class="opdrachtTop">
          <div>
            <h4>CDT.{{ opdr.opdrachtnummerjaar }}.{{ opdr.opdrachtnummer }}</h4>
            <span class="disciplineBol">{{ opdr.disciplines }}</span>
          </div>
          <div class="flex1">
            <span class="datumFix">{{ getCorrectDate(opdr.aanvraagdatum) }}</span>
            <i v-if="isOpdrachtOpen(opdr)" style="transform:translateX(4px);" :class="{ open: isOpdrachtOpen(opdr) }" class="far fa-lock-open"></i>
            <i style="padding-top:5px" v-if="!isOpdrachtOpen(opdr)" :class="{ open: isOpdrachtOpen(opdr) }" class="far fa-lock"></i>
          </div>
        </div>
        <div class="opdrachtBottom">
          <h4>Beschrijving</h4>
          <span class="block_description">{{ opdr.omschrijving }}</span>
          <h4>Discipline</h4>
          <span class="block_description">{{ opdr.disciplines }}</span>
          <button v-show="isOpdrachtOpen(opdr) && !opdr.interestedUsers?.find(e => e.userId === userData.uid) && !notice" class="opdr_button" @click="pushUserToOpdrachtInteresse(opdr.id)">
            Ik heb interesse
          </button>
          <span style="background-color: red; color: white; padding: 6px; border-radius: 5px; margin-top: 10px;" v-if="isOpdrachtOpen(opdr) && !opdr.interestedUsers?.find(e => e.userId === userData.uid) && notice">Maak je profiel compleet om te kunnen reageren op opdrachten</span>
          <span class="interesse" v-if="isOpdrachtOpen(opdr) && opdr.interestedUsers?.find(e => e.userId === userData.uid)">Je hebt interesse in deze opdracht</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
      viewMode: "unfiltered", // 'unfiltered' || 'filtered'
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    notice() {
      if (!this.userData?.userProfile) return true
      const e = this.userData?.userProfile || {}
      if (e.aantalJaarErvaring && e.aantalJaarZelfstandig && e.achternaam && e.voornaam && e.ambities && e.bedrijfsnaam && e.disciplines.length > 0 && e.geboortedatum && e.hoogstGenotenOpleiding && e.huisnummer && e.iban && e.kvknummer && e.plaats && e.postcode && e.profilePictureURL && e.straat && e.tekenprogrammas.length > 0 && e.telefoon && e.voorkeurTypeOpdrachten && e.website && e.werkgebied.length > 0 && e.werkplek.length > 0) {
        return false
      } else {
        return true
      }
    },
    opdrachten() {
      return this.$store.getters.opdrachten
    },
    filteredOpdrachten() {
      if (this.viewMode === "unfiltered") return this.opdrachten
      return this.opdrachten.filter(opdr => {
        if ((opdr.status === "Nieuw" || opdr.status === "In behandeling") && !opdr.geannuleerd && !opdr.onhold) return opdr
      })
    },
    v3opdrachten() {
      return this.$store.getters.v3opdrachten
    },
    filteredV3Opdrachten() {
      if (this.viewMode === "unfiltered") return this.v3opdrachten
      return this.v3opdrachten.filter(opdr => {
        const lastStatus = opdr.status[opdr.status.length - 1].event

        console.log(lastStatus)

        if (lastStatus < 30 && lastStatus > 9) return opdr
      })
    },
  },
  methods: {
    getCorrectDate(e) {
      if (!e) return ""
      if (e.length === 27) {
        return `${e.slice(9, 11)}-${e.slice(6, 8)}-${e.slice(1, 5)}`
        // return e.slice(1, 11)
      } else {
        return e
      }
    },
    toggleOpdrachtOpenState(event, opdr) {
      if (event.target.toString() === "[object HTMLButtonElement]") {
        return
      } else {
        opdr.open = !opdr.open
      }
    },
    pushUserToOpdrachtInteresse(opdrachtId) {
      const payload = {
        opdrachtId: opdrachtId,
        userId: this.userData.uid,
      }
      this.$store.dispatch("addUserToInterestedList", payload)
    },
    pushUserToV3OpdrachtInteresse(opdrachtId) {
      const payload = {
        opdrachtId: opdrachtId,
        userId: this.userData.uid,
      }
      this.$store.dispatch("addUserToInterestedListV3", payload)
    },

    isV3OpdrachtOpen(opdr) {
      const lastStatus = opdr.status[opdr.status.length - 1].event

      if (lastStatus < 30 && lastStatus > 9) return opdr
    },

    isOpdrachtOpen(opdr) {
      if (!opdr.onhold && !opdr.geannuleerd) {
        if (opdr.status?.toLowerCase() === "nieuw" || opdr.status?.toLowerCase() === "in behandeling") {
          return true
        }
        return false
      }
      return false
    },
    toggleViewMode() {
      if (this.viewMode === "unfiltered") return (this.viewMode = "filtered")
      return (this.viewMode = "unfiltered")
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'

.datumFix
  padding-right: 8px

.interesse
  color: $cdt_blue
  opacity: 1 !important
  padding-top: 12px
  font-weight: 700

.NieuweOpdrachtenComponent
  animation: fadeUp30px .6s ease-out 1s both
  transform: translateX(0)
  overflow-y: scroll
  position: fixed
  top: 0
  right: 0
  bottom: 0
  z-index: 10000
  background-color: $black
  min-width: 330px
  max-width: 330px
  color: white

  // border-left: 1px solid rgba(255, 255, 255, 0.4)
  .header
    h2
      font-size: 20px
      font-weight: 500
    padding: 20px 20px 50px 20px
    display: flex
    flex-direction: column
    background-color: black
    background: rgb(0,0,0)
    background: linear-gradient(180deg, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%)
    position: sticky
    top: 0
    z-index: 10
    .headerTop
      padding-bottom: 14px
    .filterWrapper
      border: 1px solid rgba(255, 255, 255, 0.1)
      background-color: #04182F
      padding: 10px
      border-radius: 8px
      color: $cdt_blue
      color: $cdt_spanColor
      display: flex
      justify-content: space-between
      align-items: center
      cursor: pointer
      transition: .2s
      &:hover
        background-color: #072241
      span
        font-size: 14px
        font-weight: 200
      .toggleFilter
        min-width: 40px
        max-width: 40px
        height: 20px
        display: flex
        align-items: center
        border-radius: 20px
        box-shadow: 0 0 0 1px $cdt_grey
        transition: .3s cubic-bezier(.53,.11,.59,1.27)
        &.toggleFilterActive
          box-shadow: 0 0 0 2px $cdt_green
        .toggle
          min-width: 16px
          max-width: 16px
          height: 16px
          border-radius: 100%
          width: 16px
          background-color: white
          transform: translateX(2px)
          transition: .3s cubic-bezier(.53,.11,.59,1.27)
          &.toggleActive
            transform: translateX(22px)
            background-color: $cdt_green

  .opdrachtenContainer
    padding: 20px
    &.blur
      .opdrachtWrapper
        filter: blur(2px)
        pointer-events: none

    .opdrachtWrapper
      box-sizing: border-box
      &:hover
        background-color: #072241
      max-height: 62px
      overflow: hidden
      display: flex
      flex-direction: column
      justify-content: flex-start
      position: relative
      // padding: 14px
      color: $cdt_spanColor
      border-radius: 8px
      font-size: 14px
      background-color: #041830
      margin-bottom: 20px
      transition: .5s
      cursor: pointer
      &.open
        max-height: 600px
      h4
        font-weight: 700
        font-size: 13px

      .opdrachtTop
        padding: 14px
        padding-right: 20px
        display: flex
        min-height: 60px
        align-items: center
        justify-content: space-between
        // border-bottom: 1px solid rgba(0, 0, 0, 1)
        .disciplineBol
          color: white

          background-color: rgba(0, 0, 0, 0) !important
          color: $cdt_spanColor
        //   margin-right: 10px
        //   width: 20px
        //   display: flex
        //   align-items: center
        //   justify-content: center
        //   height: 20px
        //   font-size: 12px
        //   font-weight: 600
        //   border-radius: 1000px
        //   background-color: white
        //   color: black
        i
          // margin-top: 2px
          transform: translateY(-3px)
          color: green
          font-size: 14px
          color: $cdt_red
          font-weight: 600
          color: $cdt_spanColor
          &.open
            color: $cdt_green

        span
          // border-radius: 1000px
          // padding: 3px 5px
          font-size: 12px
          &.open
            // background-color: $cdt_green
            color: #041830
            color: $cdt_green
          &.taken
            // background-color: red
            color: white


      .opdrachtBottom
        padding: 14px
        display: flex
        flex-direction: column
        .block_description
          color: #fff
          color: $cdt_grey
          // color: $cdt_spanColor
        .opdr_button
          font-size: 14px
          font-weight: 600
          border-radius: 1000px
          border-radius: 4px
          background-color: $cdt_blue
          -webkit-appearance: none
          border: none
          outline: none
          color: white
          display: flex
          align-items: center
          justify-content: center
          padding: 10px
          padding: 10px 8px
          width: auto
          margin-top: 10px
          cursor: pointer

@media only screen and (max-width: 600px)
  .NieuweOpdrachtenComponent
    animation: none

    z-index: 5
    min-width: 100vw
    max-width: 100vw
    padding-top: 60px

    .header
      h2
        font-size: 20px
        font-weight: 500
      padding: 20px 20px 30px 20px
      display: flex
      flex-direction: column
      background-color: black
      background: rgb(0,0,0)
      background: linear-gradient(180deg, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%)
      position: sticky
      width: 100%
      top: 0
      left: 0
      right: 0
      z-index: 10
</style>

<style lang="scss" scoped>
.pastel0 {
  background-color: #bbb;
}
.pastel1 {
  background-color: #27a4dd !important;
}
.pastel2 {
  background-color: #9dd5c0 !important;
}
.pastel3 {
  background-color: #f39cc3 !important;
}
.pastel4 {
  background-color: #f1646c !important;
}
.pastel5 {
  background-color: #fac174 !important;
}
.pastel6 {
  background-color: #8794d9 !important;
}
.pastel7 {
  background-color: #b7c8c9 !important;
}
.pastel8 {
  background-color: #e0d44b !important;
}
.pastel9 {
  background-color: #c6a89b !important;
}
.pastel10 {
  background-color: black !important;
}
</style>

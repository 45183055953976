<template>
  <div class="CtaBar">
    <span><i v-html="ctaBarContent.icon"></i> {{ ctaBarContent.text }}</span>
    <div>
      <button style="margin-right: 10px" v-if="ctaBarContent.secondaryButtonText" @click="$emit('secondaryCta')">{{ ctaBarContent.secondaryButtonText }}</button>
      <button class="pulse" v-else-if="ctaBarContent.mainButtonText" @click="$emit('mainCta')">{{ ctaBarContent.mainButtonText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["opdrachtStatus", "isPartnerOpdracht", "partnerAkkoord"],
  computed: {
    ctaBarContent() {
      if (this.opdrachtStatus === 30 && !this.isPartnerOpdracht) return { icon: '<i class="fas fa-lock"></i>', text: `Open de voorwaarden om deze te accepteren`, mainButtonText: `Toon voorwaarden` }
      if (this.opdrachtStatus === 30 && this.isPartnerOpdracht) return { icon: '<i class="fas fa-lock"></i>', text: `Accepteer de uitnodiging en ga akkoord met de voorwaarden`, mainButtonText: `Ga verder` }

      if (this.opdrachtStatus === 40 && this.isPartnerOpdracht && !this.partnerAkkoord) return { icon: '<i class="fas fa-pause"></i>', text: `Je kan verder zodra de opdrachtgever de opdracht start`, mainButtonText: ``, secondaryButtonText: `` }
      if (this.opdrachtStatus === 50 && this.isPartnerOpdracht && this.partnerAkkoord) return { icon: '<i class="fas fa-play-circle"></i>', text: `Start deze opdracht wanneer je begint met werken`, mainButtonText: `Start deze opdracht` }

      if (this.opdrachtStatus === 40 && !this.isPartnerOpdracht) return { icon: '<i class="fas fa-comment"></i>', text: `Neem contact op met de opdrachtgever en deel je intake`, mainButtonText: `Start deze opdracht`, secondaryButtonText: `Deel je intake` }
      if (this.opdrachtStatus === 50 && !this.isPartnerOpdracht) return { icon: '<i class="fas fa-play-circle"></i>', text: `Start deze opdracht wanneer je begint met werken`, mainButtonText: `Start deze opdracht` }
      if (this.opdrachtStatus === 60) return { icon: `<i class="fas fa-comment"></i>`, text: `Geef feedback over deze opdracht`, mainButtonText: `Geef feedback` }
      return ``
    },
  },
}
</script>

<style lang="sass" scoped>

@keyframes pulse
  0%
    box-shadow: 0 0 0 0 rgba(0,0,255,1)
  100%
    box-shadow: 0 0 0 30px rgba(0,0,255,0)

.pulse
  animation: pulse 2s infinite both

.locked
  opacity: .6
  cursor: not-allowed !important

.CtaBar
  background-color: #000
  padding: 12px 14px
  color: white
  display: flex
  align-items: center
  justify-content: space-between
  font-weight: 700
  font-size: 14px
  border-radius: 0 0 10px 10px
  i
    margin-left: 4px
    margin-right: 10px
  button
    i
      margin: 0 4px 0 2px
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 8px
    font-weight: 700
    border-radius: 4px
    cursor: pointer
</style>

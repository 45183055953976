<template>
  <div class="navbar flex1">
    <div @click="$router.push('/')" class="logo flex2">
      <img src="../assets/logo.png" />
      <div class="profilePicture" :style="profilePicture"></div>
      <span>
        CAD-Tekenaar.com
      </span>
    </div>

    <div class="menu flex1">
      <div @click="$router.push('/')" :class="{ active: route === 'dashboard' }" class="item">Dashboard</div>
      <div @click="$router.push('/opdrachten')" :class="{ active: route === 'opdrachten' }" class="item">
        <svg v-if="showAlert" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-exclamation" class="svg-inline--fa fa-circle-exclamation fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"></path></svg>
        Mijn opdrachten
      </div>
      <div @click="$router.push('/profiel')" :class="{ active: route === 'profiel' }" class="item">
        Profielpagina
        <i class="far fa-exclamation-circle notice" v-if="notice"></i>
      </div>
    </div>
    <div class="extra flex3">
      <span @click="logout()" class="uitlogTekst">Log uit</span>
      <i @click="logout()" class="far fa-power-off"></i>
    </div>
  </div>

  <!-- 

    mobile menu

   -->

  <div class="mobileMenu">
    <div class="item" @click="$router.push('/profiel')" :class="{ active: route === 'profiel' }">
      <i class="fal fa-address-card"></i>
      <span>Profiel</span>
    </div>
    <div class="item" @click="$router.push('/')" :class="{ active: route === 'dashboard' }">
      <i class="fal fa-home"></i>
      <span>Dashboard</span>
    </div>
    <div @click="$router.push('/opdrachten')" class="item" :class="{ active: route === 'opdrachten' }">
      <i class="fal fa-tasks"></i>
      <span>Opdrachten</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePage: 1,
    }
  },
  computed: {
    showAlert() {
      return [...this.$store.getters.opdrachtenOfUser].find(e => e.status === "Uitgezet" && e.progress && e.progress[e.progress?.length - 1].event > 9)
    },
    notice() {
      if (!this.userData?.userProfile) return true
      const e = this.userData?.userProfile || {}
      if (e.aantalJaarErvaring && e.aantalJaarZelfstandig && e.achternaam && e.voornaam && e.ambities && e.bedrijfsnaam && e.disciplines.length > 0 && e.geboortedatum && e.hoogstGenotenOpleiding && e.huisnummer && e.iban && e.kvknummer && e.plaats && e.postcode && e.profilePictureURL && e.straat && e.tekenprogrammas.length > 0 && e.telefoon && e.voorkeurTypeOpdrachten && e.website && e.werkgebied.length > 0 && e.werkplek.length > 0) {
        return false
      } else {
        return true
      }
    },
    route() {
      return this.$route.name.toLowerCase()
    },
    userData() {
      return this.$store.getters.userData
    },
    profilePicture() {
      if (!this.userData.userProfile?.profilePictureURL) return `background-image: url("https://www.povertyalliance.org/wp-content/uploads/2019/03/Portrait_Placeholder.png")`
      return `background-image: url("${this.userData.userProfile.profilePictureURL}")`
    },
    pageLoaded() {
      if (this.userData && this.profilePicture) return true
      return false
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout")
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'

.navbar
  background-color: $white
  background-color: $cdt_background_grey
  z-index: 10
  position: fixed
  top: 0
  right: 330px
  left: 0
  animation: slideDownFull .5s ease-out 0s both
  max-height: 60px
  border-bottom: 1px solid rgba(0, 0, 0, 0.06)
  // padding: 0 20px
  // background-color: white
  //
  // padding: 0 20px

  // background-color: $cdt_background_dark
  // background-color: black
  // border-bottom: 1px solid rgba(255, 255, 255, 0.4)
  // color: white !important
  .logo
    height: 32px
    cursor: pointer
    width: 25%
    padding: 0 18px
    font-weight: 700
    img
      max-height: 28px
    span
      white-space: nowrap
      padding: 0 0 0 3px
      display: none


  .profilePicture
    padding: 15px
    background-size: cover
    background-position: center
    border-radius: 100%
    transform: translateX(-4px)
    box-shadow: 0 0 0 3px $cdt_background_grey

  .menu
    overflow: hidden
    font-size: 14px
    font-weight: 600
    .item
      transition: .2s
      padding: 20px 5px
      margin: 0 10px
      cursor: pointer
      color: rgba(0, 0, 0, 0.5)
      display: flex
      align-items: center
      svg
        opacity: 1
        margin-right: 6px
        height: 14px
        path
          fill: orange
      &:hover
        box-shadow: inset 0 -3px 0px rgba(0, 0, 0, 0.1)
      &.active
        opacity: 1
        box-shadow: inset 0 -3px 0px $cdt_blue
      i
        color: $cdt_red
        border-radius: 100%
        margin-left: 2px

  .extra
    width: 25%
    // padding: 0 18px
    padding: 20px 18px
    display: flex
    cursor: pointer
    align-items: center
    &:hover
      .uitlogTekst
        opacity: 1
    .uitlogTekst
      transition: .3s
      opacity: 0
      font-size: 13px
      padding: 0 10px 0
    .profilePicture
      padding: 16px
      background-size: cover
      background-position: center
      border-radius: 100%

.mobileMenu
  display: none


.black
  background-color: black
  width: 100%

  .profilePicture
    box-shadow: 0 0 0 3px black
  i
    color: white
  .uitlogTekst
    color: white





@media only screen and (max-width: 600px)
  .uitlogTekst
    opacity: 1 !important
    white-space: nowrap
  .navbar
    width: 100%
    min-height: 60px
    border-bottom: 0
    .menu
      display: none

  .mobileMenu
    font-size: 11px
    position: fixed
    bottom: 0
    left: 0
    right: 0
    background-color: $white
    padding: 14px 10px
    padding-bottom: calc( 14px + env(safe-area-inset-bottom) )
    z-index: 10
    border-top: 2px solid $cdt_background_grey
    display: flex
    align-items: center
    justify-content: space-around
    // background-color: $cdt_blue

    .item
      display: flex
      flex-direction: column
      align-items: center
      &.active
        color: $cdt_blue
        span
          color: $cdt_blue

      i
        text-align: center
        font-size: 24px
        height: 24px
      span
        padding-top: 6px
        color: $cdt_spanColor
</style>

<template>
  <div class="blackFiller" @click="closeForm()"></div>

  <div class="startForm">
    <div class="header">
      <span>Wil je deze opdracht archiveren?</span>
    </div>
    <div class="content">
      <div>
        <p>
          Beschouw je deze opdracht niet langer als actief? Archiveer hem dan. Dit process kan hersteld worden in de toekomst.
        </p>
        <input v-model="redenVanArchiveren" placeholder="Reden van archiveren" />
      </div>
      <div class="buttonsWrapper">
        <button class="red" :class="{ clickable: formCompleted }" @click="submit()"><i class="far fa-archive"></i> Ja, archiveer</button>
        <div class="spacer"></div>
        <button class="grey" @click="closeForm()">Nee, niet archiveren</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      redenVanArchiveren: "",
    }
  },
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      if (!this.formCompleted) return alert("Vul alle velden in aub")
      this.$emit("submit", this.redenVanArchiveren)
      this.closeForm()
    },
  },
  computed: {
    formCompleted() {
      if (!this.redenVanArchiveren) return false
      return true
    },
  },
}
</script>

<style lang="sass" scoped>


.buttonsWrapper
  display: flex
  flex-direction: row !important
  > button
    width: 100% !important
  .red
    background-color: red
    i
      margin-right: 7px
  .spacer
    min-width: 14px
    max-width: 14px
  .grey
    background-color: rgba(0, 0, 0, 0.1)
    color: black
    opacity: 1 !important
    pointer-events: all !important
    cursor: pointer !important
    transition: .2s
    &:hover
      opacity: .7 !important


.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.5)


.startForm
  position: fixed
  z-index: 10002
  background-color: #fff
  box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 10px
  display: flex
  flex-direction: column
  .header
    display: flex
    padding: 30px 20px 10px
    justify-content: center
    align-items: center
    span
      font-size: 20px
      text-align: center
      font-weight: 700
  .content
    padding: 0 20px 20px 20px
    display: flex
    flex-direction: column
    font-size: 16px
    text-align: center
    color: rgba(0, 0, 0, 0.60)
    p
      max-width: 40ch
      padding-bottom: 30px
    > div
      display: flex
      flex-direction: column
      padding-top: 15px

  button
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    opacity: 0.7
    pointer-events: none
    &.clickable
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

import { db } from "../firebase"
import firebase from "firebase/app"
import "firebase/firestore"

export const nieuweOpdrachten = {
  state: {
    opdrachten: [],
    v3opdrachten: [],
    selectedOpdrachtId: "",
  },
  mutations: {
    SET_OPDRACHTEN(state, payload) {
      state.opdrachten = payload
    },
    SET_V3_OPDRACHTEN(state, payload) {
      state.v3opdrachten = payload
    },
    setSelectedOpdrachtId: (state, payload) => (state.selectedOpdrachtId = payload),
  },
  getters: {
    opdrachten: state => {
      return state.opdrachten
    },
    v3opdrachten: state => state.v3opdrachten,
    v3opdrachtenOfUser: (state, rootState) => {
      const userId = rootState.userData?.uid

      return state.v3opdrachten.filter(opdracht => opdracht.afhandeling?.tekenaar === userId)
    },

    filteredOpdrachten: state => {
      return state.opdrachten.filter(opdr => {
        if (!opdr.geannuleerd && !opdr.tekenaarsid) return opdr
      })
    },
    opdrachtenOfUser: (state, rootState) => {
      const userId = rootState.userData?.uid

      const signedOnOpdrachten_SHARED = state.opdrachten.filter(opdracht => opdracht.tekenaars?.filter(tekenaar => tekenaar.id === userId))
      const signedOnOpdrachten = state.opdrachten.filter(opdracht => opdracht.tekenaar === userId)

      return [...signedOnOpdrachten, ...signedOnOpdrachten_SHARED]
    },
    selectedOpdracht: (state, getters) => {
      return getters.v3opdrachtenOfUser.find(opdracht => opdracht.id === state.selectedOpdrachtId)
    },
  },
  actions: {
    // => Download opdrachten v.d. database
    getOpdrachten({ commit }) {
      db.collection("opdrachten")
        .orderBy("opdrachtnummer", "desc")
        .onSnapshot(snapshot => {
          const arrayOfOpdrachten = []
          snapshot.forEach(doc => {
            if (typeof doc.data().opdrachtnummer === "number") {
              arrayOfOpdrachten.push({
                ...doc.data(),
                id: doc.id,
              })
            }
          })
          commit("SET_OPDRACHTEN", arrayOfOpdrachten)
        })
    },
    getV3Opdrachten({ commit }) {
      db.collection("opdrachten")
        .orderBy("metadata.opdrachtNummer", "desc")
        .onSnapshot(snapshot => {
          const arrayOfOpdrachten = []
          snapshot.forEach(doc => {
            arrayOfOpdrachten.push({
              ...doc.data(),
              id: doc.id,
            })
          })
          commit("SET_V3_OPDRACHTEN", arrayOfOpdrachten)
        })
    },

    addUserToInterestedList(context, payload) {
      const extraUser = {
        userId: payload.userId,
        timestamp: new Date(),
        // server timestamp doesn't work in array union
      }
      db.doc(`opdrachten/${payload.opdrachtId}`).update({
        interestedUsers: firebase.firestore.FieldValue.arrayUnion(extraUser),
      })
    },
    addUserToInterestedListV3(context, payload) {
      const extraUser = {
        userId: payload.userId,
        timestamp: new Date(),
      }
      db.doc(`opdrachten/${payload.opdrachtId}`).update({
        "afhandeling.interestedUsers": firebase.firestore.FieldValue.arrayUnion(extraUser),
      })
    },
  },
}

<template>
  <transition name="notice" appear>
    <div v-show="notice" class="notice">
      <!-- <div v-if="currentRoute !== '/profiel'" class="button left" style="opacity:0">Bewerk mijn profiel</div> -->
      <span>⛔️ Nieuwe optie: Geef in je werkprofiel aan of je ervaring hebt met Tekla</span>
      <!-- <div v-if="currentRoute !== '/profiel'" @click="$router.push('/profiel')" class="button">Bewerk mijn profiel</div> -->
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      notice: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.notice = false
    }, 10000)
  },
  computed: {
    currentRoute() {
      return this.$route.path
    },
    userData() {
      return this.$store.getters.userData
    },
    progress() {
      const filledInFields = Object.keys(this.userData.userProfile).length
      const fieldsNeeded = 24
      return `${filledInFields} / ${fieldsNeeded}`
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'

.notice
  font-size: 14px
  font-weight: 600
  color: $cdt_red
  max-height: 50px
  padding: 0 18px
  height: 50px
  overflow: hidden
  display: flex
  align-items: center
  justify-content: space-between
  text-align: center
  background-color: #FFF8C4
  color: #E39D00
  width: 100%
  span
    flex-grow: 1
    text-align: center
  .button
    cursor: pointer
    background-color: white
    padding: 6px
    border-radius: 4px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)

.notice-enter-active
  transition: 0.6s ease 1.6s
.notice-leave-active
  transition: 0.4s ease
.notice-enter-from,
.notice-leave-to
  max-height: 0px


@media only screen and (max-width: 600px)
  .left
    display: none
  .notice
    flex-direction: column
    max-height: none !important
    padding: 20px
    height: auto
    .button
      margin-top: 10px
</style>

<template>
  <div class="opdrachtPreview"></div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>

.opdrachtPreview
  margin-bottom: 10px
  border: 2px solid rgba(0, 0, 0, 0.05)
  min-height: 45px
  border-radius: 10px
</style>

import { createStore } from "vuex"
import { user } from "./user"
import { userProfile } from "./userProfile"
import { nieuweOpdrachten } from "./nieuweOpdrachten"
import { onboarding } from "./onboarding"
import { appState } from "./appState"

export default createStore({
  state: {
    akkoordPopup: false,
  },
  mutations: {
    setAkkoordPopup: (state, payload) => (state.akkoordPopup = payload),
  },
  actions: {},
  modules: {
    user,
    appState,
    userProfile,
    nieuweOpdrachten,
    onboarding,
  },
})

import { db, auth } from "@/firebase"
import router from "@/router"
import { log } from "@/helpers"

export const user = {
  state: {
    previousLogin: "",
    authModuleLoaded: false,
    user: null,
    authLoader: true,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    setPreviousLogin: (state, payload) => (state.previousLogin = payload),
    setAuthModuleLoadedState(state, payload) {
      state.authModuleLoaded = payload
    },
    setAuthLoader(state, payload) {
      if (!payload) {
        setTimeout(() => {
          state.authLoader = payload
        }, 0)
      } else {
        state.authLoader = payload
      }
    },
  },
  getters: {
    isUserLoggedIn: state => {
      if (state.user) return true
      return false
    },
    userData: state => {
      return state.user
    },
    authLoader: state => {
      return state.authLoader
    },
    isAuthModuleLoaded: state => {
      return state.authModuleLoaded
    },
  },
  actions: {
    startFirebase({ commit, dispatch }) {
      log("⏳ Loading AuthModule")
      log("==========================")
      auth.onAuthStateChanged(user => {
        if (user) {
          log(`👤✅ USER LOGGED IN!`)
          log("==========================")
          if (router.currentRoute._rawValue.path === "/auth") {
            console.log("redirecting to dashboard")
            router.push("/")
          }
          commit("setAuthModuleLoadedState", true)
          commit("setAuthLoader", false)
          // => Fetch userData
          dispatch("downloadUserData", user)
          dispatch("getOpdrachten")
          dispatch("getV3Opdrachten")
          // dispatch("routerPushOnAuthStateChange", "/team")
        } else {
          log(`👤❌ No user logged in`)
          log("==========================")
          if (router.currentRoute._rawValue.path !== "/auth") {
            console.log("redirecting to auth")
            router.push("/auth")
          }
          commit("SET_USER", "")
          commit("setAuthModuleLoadedState", true)
          commit("setAuthLoader", false)
        }
      })
    },

    downloadUserData({ state, commit, dispatch }, user) {
      console.log(user.uid)
      db.collection("users")
        .doc(user.uid)
        .onSnapshot(doc => {
          if (doc.data()) {
            if (doc.data().userType !== "tekenaar") dispatch("logout")

            console.log("😃 USER DATA DOWNLOADED/UPDATED")

            if (!state.previousLogin) {
              console.log("damn")
              if (doc.data().previousLogin?.toDate()) {
                commit("setPreviousLogin", doc.data().previousLogin.toDate())
              } else {
                commit("setPreviousLogin", new Date())
              }
            }

            commit("SET_USER", {
              ...doc.data(),
              uid: user.uid,
              // previousLogin: doc.data().previousLogin?.toDate() ?? new Date(),
              // previousLogin: new Date("2021-05-19"),
            })
          } else {
            console.log("🤐 USER DATA NOT FOUND!")
            commit("SET_USER", "")
            dispatch("logout")
          }
        })

      setTimeout(() => {
        // update previousLogin
        db.doc(`users/${user.uid}`).update({
          previousLogin: new Date(),
        })
      }, 1000)
    },

    // // // // // // // // // // // // // // // // // // /.
    // // // // // // // // // // // // // // // // // // /.
    // ? HELPERS!
    // // // // // // // // // // // // // // // // // // /.
    // // // // // // // // // // // // // // // // // // /.
    routerPushOnAuthStateChange({ commit }, payload) {
      commit("setAuthLoader", true)
      setTimeout(() => {
        router.push(payload)
        commit("setAuthLoader", false)
      }, 1000)
    },
    logout({ commit }) {
      commit("setAuthLoader", true)
      setTimeout(() => {
        auth.signOut()
        console.clear()
      }, 1000)
    },
  },
}

<template>
  <div class="blackFiller" @click="closeForm()"></div>

  <div class="startForm">
    <div class="header">
      <span>Intake formulier</span>
      <div @click="closeForm()" class="closeButton">X</div>
    </div>
    <div class="content">
      <div>
        <label>Datum intake</label>
        <input v-model="startData.intakeDatum" placeholder="Startdatum" type="date" />
      </div>
      <div>
        <label>Hoe heb je contact gezocht?</label>
        <div class="options">
          <div v-for="(wijze, index) in contactWijzen" :key="index" class="option" @click="addOrSpliceContactWijze(wijze)" :class="{ active: startData.contactWijze.includes(wijze) }"><i class="fas fa-check-circle"></i> {{ wijze }}</div>
        </div>
      </div>

      <div>
        <label>Omschrijf de intake</label>
        <textarea v-model="startData.content" placeholder="Korte omschrijving van je gesprek, afspraken, slagingskans."></textarea>
      </div>

      <br />
      <button :class="{ clickable: formCompleted }" @click="submit()">Sla op</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactWijzen: ["email", "telefoon", "anders..."],
      startData: {
        intakeDatum: "",
        content: "",
        contactWijze: [],
      },
    }
  },
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      const e = this.startData
      if (!e.intakeDatum || !e.content || !e.contactWijze) return alert("Vul alle velden in aub")
      this.$emit("submit", { ...e, intakeDatum: new Date(e.intakeDatum) })
      this.closeForm()
    },
    //
    //
    //
    addOrSpliceContactWijze(wijze) {
      const array = this.startData.contactWijze
      if (array.includes(wijze)) return array.splice(array.indexOf(wijze), 1)
      return array.push(wijze)
    },
  },
  computed: {
    formCompleted() {
      const e = this.startData
      if (!e.intakeDatum || !e.content || !e.contactWijze) return false
      return true
    },
  },
}
</script>

<style lang="sass" scoped>

.options
  border: 1px solid rgba(0, 0, 0, 0.1)
  border-bottom: none

.option
  text-transform: capitalize
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  padding: 10px
  overflow: hidden
  font-size: 14px
  cursor: pointer
  position: relative
  transition: .25s
  i
    transition: .25s
    color: #62E887
    transform: translateX(-27px)
    position: absolute
    top: 14px
    left: 10px
    margin-right: 4px
  &.active
    font-weight: 600
    background-color: rgba(0, 0, 0, 0.04)
    padding-left: 30px
    i
      transform: translateX(0)


.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.5)


.startForm
  position: fixed
  z-index: 10002
  background-color: #fff
  box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 10px
  display: flex
  flex-direction: column
  .header
    display: flex
    padding: 20px
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  .content
    padding: 0 20px 20px 20px
    display: flex
    flex-direction: column
    > div
      display: flex
      flex-direction: column
      padding-top: 15px

  button
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    opacity: 0.4
    pointer-events: none
    &.clickable
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

<template>
  <div class="cc">
    <WelcomeNotification />
    <!-- <div v-if="omzet" class="omzetWrapper">
      <div class="omzetBalk">Gefactureerd door jou via CAD-Tekenaar.com 2021: {{ omzet }}</div>
    </div> -->
    <div class="body flex1">
      <div class="blocks">
        <div @click="$router.push('/profiel')" class="block dark">
          <div class="top">
            <h3 v-if="notice">Creëer je profiel</h3>
            <h3 v-if="!notice">Jouw profiel</h3>
            <p>Een up to date profiel verhoogt je kansen op een mooie opdrachten via CAD-Tekenaar.com</p>
          </div>
          <div class="middle">
            <div class="circle profilePicture" :style="profilePicture"></div>
          </div>
          <div class="bottom">
            <div v-if="notice" class="button">Creëer je profiel</div>
            <div v-if="!notice" class="button">Ga naar je profiel</div>
          </div>
        </div>

        <div class="block" @click="$router.push('/opdrachten')" v-if="hasAccesToOpdrachtenPagina">
          <div class="top">
            <h3>Jouw opdrachten</h3>
          </div>

          <div class="middle">
            <div class="circle grey">
              <i class="fad fa-construction"></i>
            </div>
          </div>

          <div v-if="false" class="middle opdrachtenView">
            <div class="opdracht" v-for="(opdracht, i) in getOpdrachtenPerStatus('uitgezet')" :key="i">
              <span>CDT.{{ opdracht.opdrachtnummerjaar }}.{{ opdracht.opdrachtnummer }} </span>
              <span class="tag">
                {{ opdracht.discipline }}
              </span>
            </div>
            <div class="opdracht" v-for="(opdracht, i) in getOpdrachtenPerStatus('geaccepteerd')" :key="i">
              <span> CDT.{{ opdracht.opdrachtnummerjaar }}.{{ opdracht.opdrachtnummer }} </span>
              <span class="tag">
                {{ opdracht.discipline }}
              </span>
            </div>
            <div class="opdracht" v-for="(opdracht, i) in getOpdrachtenPerStatus('gestart')" :key="i">
              <span> CDT.{{ opdracht.opdrachtnummerjaar }}.{{ opdracht.opdrachtnummer }} </span>
              <span class="tag">
                {{ opdracht.discipline }}
              </span>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Ga naar mijn opdrachten</div>
          </div>
        </div>

        <div class="block locked" :class="{ lockable: notice }" v-else>
          <div class="top">
            <h3>Jouw opdrachten</h3>
            <p></p>
          </div>
          <div class="middle">
            <div class="circle red">
              <i class="fad fa-construction"></i>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Binnenkort beschikbaar</div>
          </div>
        </div>

        <div class="block" :class="{ lockable: notice }">
          <div class="top">
            <h3>Lees de blog</h3>
            <!-- <p>Ontdek het laatste nieuws over CAD-Tekenaar.com en blijf op de hoogte.</p> -->
          </div>
          <div class="middle">
            <div class="circle" style="color:#66CCCC">
              <i class="fad fa-book-reader"></i>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Binnenkort beschikbaar</div>
            <!-- <div class="button">Open de blog</div> -->
          </div>
        </div>

        <!--  -->
        <div class="block" :class="{ lockable: notice }" @click="open = 'Fee betalen'">
          <div class="top">
            <h3>Fee betalen</h3>
            <p>Opdracht gefactureerd? Betaal direct de fee met IDeal via dit formulier.</p>
          </div>
          <div class="middle">
            <div class="circle purple">
              <i class="fab fa-ideal"></i>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Factureer opdracht</div>
          </div>
        </div>
        <!--  -->
        <div class="block" :class="{ lockable: notice }" @click="open = 'Start opdracht'">
          <div class="top">
            <h3>Start een opdracht</h3>
            <p>Akkoord met de opdrachtgever? Start direct je opdracht via de teampagina.</p>
          </div>
          <div class="middle">
            <div class="circle green">
              <i class="fad fa-play-circle green"></i>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Start opdracht</div>
          </div>
        </div>
        <div class="block" :class="{ lockable: notice }" @click="open = 'Factureer opdracht'">
          <div class="top">
            <h3>Afmelden opdracht</h3>
            <p>Yes! klaar met je opdracht? Meld je opdracht direct af voor een snelle facturatie.</p>
          </div>
          <div class="middle">
            <div class="circle red">
              <i class="fad fa-file-invoice"></i>
            </div>
          </div>
          <div class="bottom">
            <div class="button">Factureer opdracht</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeNotification from "@/newComponents2/WelcomeNotification"

export default {
  components: { WelcomeNotification },
  data() {
    return {
      omzet: "",
    }
  },
  // mounted() {
  //   this.timeOutBondedTrigger()
  // },
  methods: {
    getOpdrachtenPerStatus(status) {
      let opdrachtenOfUser
      if (this.showGearchiveerdeOpdrachten) opdrachtenOfUser = this.$store.getters.opdrachtenOfUser || []
      if (!this.showGearchiveerdeOpdrachten) opdrachtenOfUser = [...this.$store.getters.opdrachtenOfUser].filter(e => e.progress && e.progress[e.progress?.length - 1].event > 9) || []
      return opdrachtenOfUser.filter(opdracht => opdracht.status.toLowerCase() === status) || []
    },
    // timeOutBondedTrigger() {
    //   this.getData()
    // },
    // getData() {
    //   const api = "https://spreadsheets.google.com/feeds/cells/1DsvgWSLS2F1XXK6rhh-YAdPCDVsF2QZvSFBxLVg824w/2/public/full?alt=json"
    //   // const tekenaarEmail = this.userData.userProfile.emailuserData.userProfile.email
    //   const tekenaarEmail = "info@klaas-design.nl"
    //   axios.get(api).then(response => {
    //     let lijst = []
    //     let newObject = []
    //     let index = 0
    //     response.data.feed.entry.forEach(object => {
    //       if (lijst.length < 1 && index < 7) {
    //         if (lijst.length === 1 && index === 0) {
    //           newObject.push("Van Veen")
    //           console.log("yes!")
    //         }
    //         index++
    //         newObject.push(object.content.$t)
    //       } else if (lijst.length > 0 && index < 6) {
    //         index++
    //         newObject.push(object.content.$t)
    //       } else {
    //         index = 0
    //         lijst.push(newObject)
    //         newObject = []
    //       }
    //     })
    //     lijst.forEach(row => {
    //       // console.log(row[0]);
    //       if (row[0] === tekenaarEmail) {
    //         this.omzet = row[1]
    //         console.log(`De omzet van deze tekenaar in 2020 is: ${row[1]}`)
    //       }
    //     })
    //   })
    // },
  },
  computed: {
    hasAccesToOpdrachtenPagina() {
      return this.$store.getters.opdrachtenOfUser.length
    },
    userData() {
      return this.$store.getters.userData
    },
    profilePicture() {
      if (!this.userData.userProfile?.profilePictureURL) return `background-image: url("https://www.povertyalliance.org/wp-content/uploads/2019/03/Portrait_Placeholder.png")`
      return `background-image: url("${this.userData.userProfile.profilePictureURL}")`
    },
    pools() {
      return this.userData?.pools || []
    },
    opdrachtenOfUser() {
      return this.$store.getters.opdrachtenOfUser || []
    },
    notice() {
      if (!this.userData?.userProfile) return true
      const e = this.userData?.userProfile || {}
      if (e.aantalJaarErvaring && e.aantalJaarZelfstandig && e.achternaam && e.voornaam && e.ambities && e.bedrijfsnaam && e.disciplines.length > 0 && e.geboortedatum && e.hoogstGenotenOpleiding && e.huisnummer && e.iban && e.kvknummer && e.plaats && e.postcode && e.profilePictureURL && e.straat && e.tekenprogrammas.length > 0 && e.telefoon && e.voorkeurTypeOpdrachten && e.website && e.werkgebied.length > 0 && e.werkplek.length > 0) {
        return false
      } else {
        return true
      }
    },
    //
    open: {
      get() {
        return this.$store.state.appState.open
      },
      set(e) {
        this.$store.commit("changeOpen", e)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'


.opdrachtenView
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start !important
  padding-top: 8px !important
  overflow-y: hidden
  width: 100%
  max-height: 217px
  position: relative

  &::after
    position: absolute
    inset: 0
    z-index: 1
    content: ''
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.20211834733893552) 100%)

  .opdracht
    padding: 0.5rem
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
    border-radius: 4px
    margin-bottom: 12px
    width: 98%
    display: flex
    align-items: center
    justify-content: space-between
    .tag
      background-color: rgba(0, 0, 0, 0.1)
      border-radius: 10000px
      font-size: 13px
      padding: 3px 6px

.omzetWrapper
  padding: 0 40px 40px
  .omzetBalk
    background-color: $cdt-green
    color: green
    font-weight: 800
    padding: 12px
    border-radius: 8px


.blocks
  animation: fadeUp30px .4s ease-out 0.7s both
  display: flex
  align-items: stretch
  justify-content: space-between
  width: 100%
  flex-wrap: wrap
  .block
    cursor: pointer
    border-radius: 8px
    margin-bottom: 4%
    background-color: white
    width: 31%
    padding: 24px
    min-height: 300px
    display: flex
    flex-direction: column
    justify-content: space-between
    transition: .2s
    border: 2px solid rgba(0, 0, 0, 0.05)
    &.lockable
      pointer-events: none
      opacity: .7 !important
      filter: blur(2px)
    &:hover
      // background-color: $cdt_blue_003
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1)
    &.dark
      background-color: $cdt_background_dark
      color: white

    &.locked
      i
        color: grey !important

    .top
      h3
        font-size: 18px
      p
        padding-top: 9px
        font-size: 12.5px
        line-height: 19px
        color: $cdt_spanColor
    .middle
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      padding: 30px 0

      .circle
        width: 100px
        height: 100px
        background-color: $cdt_green
        border-radius: 100000px
        display: flex
        align-items: center
        justify-content: center
        font-size: 32px
        color: white
        background-color: $cdt_background_grey
        background-color: white
        &.profilePicture
          background-position: center
          background-size: cover
        &.green
          color: $cdt_green
        &.red
          color: $cdt_red
        &.purple
          color: purple
        &.grey
          color: #888


    .bottom
      padding-top: 12px

      .button
        background-color: $cdt_blue
        background-color: $cdt_background_grey
        color: black
        // color: white
        text-align: center
        padding: 14px
        border-radius: 4px
        font-weight: 600
        font-size: 14px



.cc
  background-color: $cdt_background_grey
  padding: 0
  .body
    padding: 0 40px
    align-items: flex-start
    align-items: stretch

    .divider
      width: 40px


    .actions
      animation: fadeUp30px .4s ease-out 0.7s both
      flex-grow: 1
      border-radius: 8px
      padding: 24px 0 100px
      border: 2px solid $cdt_background_grey
      .header
        display: none
        padding: 0 24px 24px
      .body
        display: flex
        .item
          &:first-child
            border-top: 2px solid $cdt_background_grey
          overflow: hidden
          background-color: $white
          position: relative
          cursor: pointer
          padding: 20px 24px
          border-bottom: 2px solid $cdt_background_grey
          color: $cdt_blue
          font-weight: 600
          transition: .2s
          &:hover
            background-color: $cdt_blue_003
            i
              transform: translateX(14px)
              &.fa-angle-right
                transform: translateX(64px)
                transition: .3s
              &.absolute
                transform: translate(0, -50%)
            span
              transform: translateX(14px)
          i
            &.fa-angle-right
                transition: .6s
            &.absolute
              position: absolute
              left: 18px
              top: 50%
              transition: .5s
              transform: translate(-34px, -50%)
            transition: .3s
            width: 20px
            font-size: 18px
            &.green
              color: $cdt_green
            &.red
              color: $cdt_red
          span
            transition: .3s
            padding-left: 10px
            // color: $cdt_spanColor
            font-size: 15px



    .profile
      animation: fadeUp30px .4s ease-out 0.6s both
      display: flex
      flex-direction: column
      border-radius: 8px
      padding: 24px
      background-color: $white
      border: 2px solid $cdt_background_grey
      white-space: nowrap

      .body
        padding: 3vw 0 4vw 0
        // padding: 50px 0 60px 0
        text-align: center

        .imageWrapper
          display: flex
          align-items: center
          justify-content: center
          padding-bottom: 24px
          position: relative
          .checkmark
            width: 22px
            height: 22px
            background-color: $cdt_green
            position: absolute
            border-radius: 100%
            left: 54px
            box-shadow: 0 0 0 4px white
            background-image: url('../assets/images/checkmark.svg')
            background-repeat: no-repeat
            background-position: center
            top: 80px

          .profilePicture
            padding: 60px
            background-size: cover
            background-position: center
            background-color: black
            border-radius: 1000000px

        span
          font-size: 14px
          opacity: .5

      .footer
        cursor: pointer
        background-color: $cdt_blue_01
        color: $cdt_blue
        font-size: 14px
        padding: 14px 60px
        border-radius: 5px



@media only screen and (max-width: 1100px)
  .blocks
    flex-wrap: wrap
  .divider
    min-width: 20px
  .block
    min-width: 220px
    width: 100%
    max-width: none
    width: calc( 50% - 20px) !important


@media only screen and (max-width: 600px)
  .cc
    padding: 18px 0
    padding-bottom: 60px

    .headerSection
      margin-bottom: 10px
      padding: 30px 20px 30px

    .body
      flex-direction: column
      padding: 20px

      .block
        position: relative
        flex-direction: row
        width: 100%
        max-width: none
        min-width: 100%
        min-height: 0
        max-height: none
        .bottom
          display: none
        .middle
          width: 24px
          margin: 0
          padding: 0
          position: absolute
          top: 30spx
          right: 20px
          .circle
            font-size: 20px
            width: 24px
            height: 24px

      .profile
        display: none

      .actions
        .header
          display: none
        border: none
</style>

<template>
  <div class="page">
    <Navbar v-if="pageAccess" />
    <Notice v-if="pageAccess" />
    <NoticeNew v-if="pageAccess" />
    <!-- <NoticeVeoliaAcceptatie v-if="pageAccess" /> -->
    <router-view v-if="pageAccess" />
  </div>
  <NieuweOpdrachten class="opdrachten" v-if="pageAccess" />
  <SlideOuts />
</template>

<script>
import Navbar from "@/newComponents2/Navbar"
import Notice from "@/newComponents2/Notice"
// import NoticeVeoliaAcceptatie from "@/newComponents2/NoticeVeoliaAcceptatie"
import NoticeNew from "@/newComponents2/NoticeNew"
import NieuweOpdrachten from "@/newComponents2/NieuweOpdrachten"
import SlideOuts from "@/newComponents2/SlideOuts"

export default {
  components: { Navbar, Notice, NieuweOpdrachten, SlideOuts, NoticeNew },
  data() {
    return {
      // notice: false,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.userData.pools?.includes("fVYDecMiy7Vhcjh1cwgP") && !this.userData.akkoorden?.pools?.includes("fVYDecMiy7Vhcjh1cwgP")) {
        this.$store.commit("setAkkoordPopup", true)
      }
    }, 2000)
  },
  computed: {
    authModuleLoaded() {
      return this.$store.getters.isAuthModuleLoaded
    },
    userData() {
      return this.$store.getters.userData
    },
    pageAccess() {
      if (this.authModuleLoaded && this.userData) return true
      return false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

$color: #28394A

.page
  animation: fadeIn 1.7s ease-in 0s both
  overflow-x: hidden
  padding-top: 60px
  color: $color
  background-color: $cdt_background_grey
  min-height: 100vh
  max-height: 100vh
  width: calc( 100vw - 330px )
</style>

<style lang="sass">
.flexCenter
  display: flex
  align-items: center
  justify-content: center
.flex1
  display: flex
  align-items: center
  justify-content: space-between
.flex2
  display: flex
  align-items: center
  justify-content: flex-start
.flex3
  display: flex
  align-items: center
  justify-content: flex-end

@media only screen and (max-width: 600px)
  .page
    max-width: 100vw
    min-width: 100vw

  .opdrachten
    display: none
</style>

import { createRouter, createWebHistory } from "vue-router"
import Home from "@/views/Home.vue"
import Auth from "@/views/Auth.vue"
import Dashboard from "@/views/Dashboard"
import Profiel from "@/views/Werkprofiel"
// import Opdrachten from "@/newComponents2/NieuweOpdrachten"
import Opdrachten from "@/views/Opdrachten"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/profiel",
        name: "Profiel",
        component: Profiel,
      },
      {
        path: "/opdrachten",
        name: "Opdrachten",
        component: Opdrachten,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  // // // //
  // // // //
  // REDIRECTS
  // // // //
  // // // //
  {
    path: "/rmlp/cad-tekenaar-com-evaluatieformulier/",
    beforeEnter() {
      window.location.href = "https://form.jotform.com/200211910424336"
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

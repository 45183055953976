<template>
  <div class="blackFiller" @click="closeForm()"></div>

  <div class="startForm">
    <div class="header">
      <span>Deel je feedback</span>
      <div @click="closeForm()" class="closeButton">X</div>
    </div>
    <div class="content">
      <div>
        <label>Feedback / Update</label>
        <textarea v-model="feedback" placeholder="Deel hier je feedback / update over deze opdracht"></textarea>
      </div>
      <br />
      <button :class="{ clickable: formCompleted }" @click="submit()"><i class="fas fa-comment"></i> Sla mijn feedback op</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedback: "",
    }
  },
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      if (!this.formCompleted) return alert("Vul alle velden in aub")
      this.$emit("submit", this.feedback)
      this.closeForm()
    },
  },
  computed: {
    formCompleted() {
      if (!this.feedback) return false
      return true
    },
  },
}
</script>

<style lang="sass" scoped>

.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.5)


.startForm
  position: fixed
  z-index: 10002
  background-color: #fff
  box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 10px
  display: flex
  flex-direction: column
  .header
    display: flex
    padding: 20px
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  .content
    padding: 0 20px 20px 20px
    display: flex
    flex-direction: column
    > div
      display: flex
      flex-direction: column
      padding-top: 15px

  button
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    opacity: 0.4
    pointer-events: none
    &.clickable
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

<template>
  <transition name="authLoader">
    <div v-show="authLoader" class="authLoader">
      <div class="spinner">
        <img src="../assets/images/loader.gif" />
        <!-- <img src="../assets/images/customLoader.gif" /> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    authLoader() {
      // return true
      return this.$store.getters.authLoader
    },
  },
}
</script>

<style lang="sass" scoped>

@keyframes scaleIn
  0%
    opacity: 0
    transform: scale(1.2)
  100%
    opacity: 1
    transform: scale(1)


.authLoader
  // animation: scaleIn 0.3s ease 0s 1 both
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  transform: scale(1)
  display: flex
  align-items: center
  justify-content: center
  background-color: rgba(0, 0, 0, 0.6)
  z-index: 100
  .spinner
    transform: scale(1)
    background-color: black
    padding: 20px
    border-radius: 10px
    display: flex
    align-items: center
    justify-content: center
    img
      height: 30px

// .authLoader
//   // animation: scaleIn 0.3s ease 0s 1 both
//   position: fixed
//   top: 0
//   right: 0
//   bottom: 0
//   left: 0
//   transform: scale(1)
//   display: flex
//   align-items: center
//   justify-content: center
//   background-color: rgba(0, 0, 0, 0.6)
//   background-color: rgba(0, 0, 0, 1)
//   z-index: 100
//   .spinner
//     transform: scale(1)
//     background-color: black
//     padding: 20px
//     padding: 0px
//     border-radius: 10px
//     display: flex
//     align-items: center
//     justify-content: center
//     img
//       height: 70px
//       border-radius: 10px

.authLoader-enter-active,
.authLoader-leave-active
  transition: 0.3s ease
  .spinner
    transition: 0.3s ease

.authLoader-enter-from
  .spinner
    transform: scale(1.2)
  opacity: 0
.authLoader-leave-to
  opacity: 0
  .spinner
    transform: scale(0.8)
</style>

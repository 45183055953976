<template>
  <div class="popupWrapper" v-if="showPopup && !popupClicked">
    <div class="popup">
      <div class="header">
        <img src="../../assets/images/yes.jpg" />
      </div>
      <div class="body">
        <h1>🎉</h1>
        <h2>Gefeliciteerd!</h2>
        <p style="opacity: 0.6; padding-top: 10px;">Je bent uitgenodigd voor een opdracht</p>
        <button @click="handleClick()">Ga snel aan de slag</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"

export default {
  setup() {
    const store = useStore()
    const router = useRouter()

    const showPopup = ref(false)
    const popupClicked = ref(false)

    const handleClick = () => {
      popupClicked.value = true
      router.push("/opdrachten")
    }

    watchEffect(() => {
      const getStatusCode = opdracht => opdracht.status[opdracht.status.length - 1].event

      const uitnodigingen = store.getters.v3opdrachtenOfUser.find(e => getStatusCode(e) === 30)

      if (uitnodigingen) showPopup.value = true
    })

    return {
      showPopup,
      popupClicked,
      handleClick,
    }
  },
}
</script>

<style lang="sass" scoped>

@keyframes popIn
  0%
    transform: scale(0%)


  12%
    transform: scale(10.89%)


  24%
    transform: scale(43.56%)


  36%
    transform: scale(98.01%)


  54%
    transform: scale(75.02%)


  74%
    transform: scale(98.37%)


  82%
    transform: scale(93.75%)


  92%
    transform: scale(99.34%)

  96%
    transform: scale(98.46%)


  100%
    transform: scale(100%)


@keyframes fadeBackgroundColor
  0%
    background-color: rgba(0, 0, 0, 0)
  100%
    background-color: rgba(0, 0, 0, 0.4)




.popupWrapper
  background-color: rgba(0, 0, 0, 0.5)
  position: fixed
  inset: 0
  z-index: 1000000
  pointer-events: auto
  display: flex
  align-items: center
  justify-content: center
  animation: fadeBackgroundColor .4s both

  .popup
    pointer-events: all
    animation: popIn .8s .4s both
    box-shadow: 40px 0 80px rgba(0, 0, 0, 0.2)
    background-color: white
    display: flex
    flex-direction: column
    border-radius: 20px
    overflow: hidden
    .header
      overflow: hidden
      padding: 30px 50px 0
      display: flex
      align-items: center
      justify-content: center
      background-color: #ffc529
      img
        height: 200px
    .body
      padding: 30px
      max-width: 40ch
      button
        width: 100%
        cursor: pointer
        background-color: blue
        color: white
        margin-top: 20px
        border: none
        font-weight: 600
        font-size: 16px
        padding: 14px 18px
        border-radius: 5px
        outline: none
</style>

<template>
  <div class="slideOut" :class="{ open: open }">
    <div class="wrap">
      <div @click="open = !open" class="button" :class="{ open: !open }">
        <img src="../assets/images/arrow.svg" />
      </div>
    </div>
    <iframe v-if="open === 'Blog'" src="https://cad-tekenaar.com/blog" frameborder="0"></iframe>
    <iframe v-show="open === 'Start opdracht'" src="https://form.jotform.com/200121340507032" frameborder="0"></iframe>
    <iframe v-show="open === 'Factureer opdracht'" src="https://form.jotform.com/200083847116047" frameborder="0"></iframe>
    <FeeBetalen v-show="open === 'Fee betalen'" />
  </div>

  <transition name="showCover">
    <div @click="open = ''" v-show="open" class="cover"></div>
  </transition>
</template>

<script>
import FeeBetalen from "./FeeBetalen"

export default {
  name: "SlideOuts",
  components: { FeeBetalen },
  computed: {
    open: {
      get() {
        return this.$store.state.appState.open
      },
      set(e) {
        this.$store.commit("changeOpen", e)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

@keyframes pulse
  0%
    box-shadow: 0 0 0 0px rgba(47, 204, 132, 0.2)
  50%
    box-shadow: 0 0 0 10px rgba(47, 204, 132, 0.2)
  100%
    box-shadow: 0 0 0 0 rgba(47, 204, 132, 0.2)

.wrap
  position: absolute
  width: 50px
  height: 50px
  border-radius: 1000px
  top: 50%
  transform: translate(-50%, -50%)
  transition: .3s
  left: 0
  &:hover
    transform: translate(-50%, -50%) scale(1.1)

.slideOut
  background-color: #F3F5F9
  position: fixed
  top: 0
  right: 0
  bottom: 0
  // padding: 160px
  z-index: 1
  transition: .6s .3s cubic-bezier(.73,0,.36,1.06)

  transform: translateX(100%)
  &.open
    transform: translateX(0)

  .button
    width: 50px
    height: 50px
    border-radius: 1000px
    background-color: #2FCC84
    // top: 50%
    // left: 0
    // transform: translate(-50%, -50%)
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: 1s cubic-bezier(.89,-0.46,.43,.78)
    &:hover
      // transition: .3s
      // transform: translate(-50%, -50%) scale(1)
      animation: pulse 2s ease infinite forwards
    &.open
      transform: translate(700%, -50%) scale(0)
    img
      max-height: 14px


.cover
  position: fixed
  cursor: pointer
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.7)
  // backdrop-filter: blur(10px)
  z-index: 999

.slideOut
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 25%
  max-width: 75%
  // padding: 20px 100px
  z-index: 1000000
  background-color: $cdt_background_grey
  iframe
    // max-width: 50%
    // padding-top: 100px
    // background-color: #fff
    // border-radius: 20px
    width: 100%
    height: 100%
    overflow: hidden




.showSlideOut-enter-active,
.showSlideOut-leave-active
  transition: 0.5s ease
.showSlideOut-enter-from,
.showSlideOut-leave-to
  transform: translateX(100%) !important

.showCover-enter-active
  transition: 0.5s ease
.showCover-leave-active
  transition: 0.5s .8s ease
.showCover-enter-from,
.showCover-leave-to
  opacity: 0
@media only screen and (max-width: 600px)
  .slideOut
    width: 100% !important
    max-width: none !important
    left: 0
</style>

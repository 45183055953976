<template>
  <AccepteerForm v-if="showAccepteerForm" :isPartnerOpdracht="isPartnerOpdracht" @submit="accepteerOpdracht($event)" @close="showAccepteerForm = false" />
  <StartForm v-if="showStartForm" :isPartnerOpdracht="isPartnerOpdracht" @submit="startOpdracht($event)" @close="showStartForm = false" />
  <NieuweFactuur v-if="showFactuurForm" :isPartnerOpdracht="isPartnerOpdracht" @submit="factureerOpdracht($event)" @close="showFactuurForm = false" />
  <IntakeForm v-if="showIntakeForm" @submit="saveIntakeOpdracht($event)" @close="showIntakeForm = false" />
  <FeedbackForm v-if="showFeedbackForm" @submit="saveFeedbackOpdracht($event)" @close="showFeedbackForm = false" />

  <FactuurExampleForm v-if="showFactuurExampleForm" :factuur="showFactuurExampleForm" @close="showFactuurExampleForm = false" />

  <!-- ARCHIVEREN VAN OPDRACHT!!! -->
  <ArchiveerForm v-if="showArchiveerForm" @submit="archiveerOpdracht($event)" @close="showArchiveerForm = false" />

  <transition name="fadein">
    <div v-if="selectedOpdracht" class="blackFiller" @click="closePopup()"></div>
  </transition>

  <transition name="popin">
    <div v-if="selectedOpdracht" class="opdrachtPopup" ref="popup">
      <div class="logoAbsoluteWrapper">
        <img v-if="isPartnerOpdracht" src="/images/veolia-logo.png" />
        <img v-else src="/images/logo.png" />
        <div class="tooltip" v-if="isPartnerOpdracht">Deze opdracht is binnengekomen via een partnerprogramma waar jij bent ingeschreven</div>
        <div class="tooltip" v-else>Deze opdracht is binnengekomen via CAD-Tekenaar.com</div>
      </div>
      <div class="header">
        <h3>
          {{ opdrachtNummerFormatted }}
        </h3>
        <div @click="closePopup()" class="closeButton flex-center"><i class="fal fa-times"></i></div>
      </div>
      <div class="contentWrapper">
        <div class="blurLayer" v-if="isOpdrachtGearchiveerd">
          <div style="z-index: 2" v-if="isOpdrachtGearchiveerd">
            <h1>Deze opdracht is momenteel gearchiveerd...</h1>
            <button @click="deArchiveerOpdracht()">Zet terug naar actief</button>
          </div>
        </div>
        <div class="explanationWrapper">
          <transition name="slide">
            <div :key="explanation.titel" class="explanation" :class="{ partnerBackground: isPartnerOpdracht }">
              <div class="iconHolder">
                <div v-html="explanation.icon"></div>
              </div>
              <div>
                <h3>{{ explanation.titel || "" }}</h3>
                <p>{{ explanation.omschrijving || "" }}</p>
              </div>
            </div>
          </transition>
        </div>

        <CtaBar :opdrachtStatus="opdrachtStatus" :isPartnerOpdracht="isPartnerOpdracht" :partnerAkkoord="selectedOpdracht.akkoord" @mainCta="triggerDynamicCTAFunction()" @secondaryCta="showIntakeForm = true" />
        <Facturen :userId="userId" :opdrachtId="selectedOpdracht.id" @addNieuweFactuur="showFactuurForm = true" @showFactuur="showFactuurExampleForm = $event" v-if="opdrachtStatus === 60" />

        <Timestamp v-if="isPartnerOpdracht && opdrachtStatusNumber > 3" :stamp="'Opdracht gestart door {veolia}:'" />
        <Timestamp v-else-if="opdrachtStatusNumber > 3" :stamp="'Opdracht gestart:'" />
        <Timestamp v-if="selectedOpdracht.afhandeling.intakeTekenaar && !isPartnerOpdracht && opdrachtStatusNumber > 2" :stamp="`Intake gedeeld: ${selectedOpdracht.afhandeling.intakeTekenaar?.timestamp.toDate().toDateString()}`" />
        <Timestamp v-if="isPartnerOpdracht && opdrachtStatusNumber > 2" :isGreen="true" :stamp="'Aanbieding geaccepteerd:'" />
        <Timestamp v-if="isPartnerOpdracht && opdrachtStatusNumber > 2" :stamp="'Aanbieding verstuurd:'" />
        <Timestamp v-else-if="opdrachtStatusNumber > 1" :stamp="'Akkoord gegeven'" />

        <Timestamp :lineDown="true" :stamp="'Uitnodiging ontvangen'" />
        <div class="block">
          <OpdrachtInfoBlock :selectedOpdracht="selectedOpdracht" :opdrachtStatus="opdrachtStatus" :isPartnerOpdracht="isPartnerOpdracht" />
        </div>
        <br />

        <div class="archiveerBalk" v-if="!isOpdrachtGearchiveerd && opdrachtStatus !== 'gestart'">
          <div class="line"></div>
          <span class="archiveerButton" @click="showArchiveerForm = true"><i class="far fa-archive"></i> Archiveer deze opdracht </span>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"

import { db } from "@/firebase"
import OpdrachtInfoBlock from "./OpdrachtInfoBlock"
import Facturen from "./Facturen"
import AccepteerForm from "./modals/AccepteerForm"
import StartForm from "./modals/StartForm"
import IntakeForm from "./modals/IntakeForm"
import NieuweFactuur from "./modals/NieuweFactuur"
import FeedbackForm from "./modals/FeedbackForm"
import ArchiveerForm from "./modals/ArchiveerForm"
import FactuurExampleForm from "./modals/FactuurExampleForm"
import CtaBar from "./CtaBar"
import Timestamp from "./Timestamp"

// import { createAnimation } from "@ionic/core"
export default {
  name: "OpdrachtPopup",
  components: { Timestamp, FactuurExampleForm, OpdrachtInfoBlock, AccepteerForm, ArchiveerForm, StartForm, NieuweFactuur, Facturen, IntakeForm, FeedbackForm, CtaBar },

  data() {
    return {
      showFactuurExampleForm: false,
      showAccepteerForm: false,
      showStartForm: false,
      showFactuurForm: false,
      showIntakeForm: false,
      showFeedbackForm: false,
      //
      showArchiveerForm: false,
    }
  },
  computed: {
    opdrachtNummerFormatted() {
      return `CDT.${new Date(this.selectedOpdracht.metadata.createdAt.toDate()).getFullYear()}.${this.selectedOpdracht.metadata.opdrachtNummer}`
    },
    userData() {
      return this.$store.getters.userData
    },
    isPartnerOpdracht() {
      const veoliaPoolId = "fVYDecMiy7Vhcjh1cwgP"
      if (this.selectedOpdracht.poolId === veoliaPoolId) return true
      return false
    },
    explanation() {
      if (this.opdrachtStatus === 30 && !this.isPartnerOpdracht) return { icon: '<i class="fal fa-signature"></i>', titel: `Je bent uitgenodigd voor deze opdracht`, omschrijving: `Accepteer de CAD-Tekenaar.com voorwaarden om de contactgegevens zichtbaar te krijgen.` }
      if (this.opdrachtStatus === 30 && this.isPartnerOpdracht) return { icon: '<i class="fal fa-signature"></i>', titel: `Je bent uitgenodigd voor deze opdracht`, omschrijving: `Een partner van CAD-Tekenaar.com heeft je uitgenodigd voor een opdracht. Accepteer hieronder de voorwaarden en stuur jouw aanbieding naar de opdrachtgever.` }
      if (this.opdrachtStatus === 40 && !this.isPartnerOpdracht) return { noRotate: true, icon: '<i class="fal fa-users"></i>', titel: `Hoppa! Deze opdracht is aan jouw profiel gekoppeld!`, omschrijving: `Neem binnen 3 dagen contact op met de opdrachtgever om zijn of haar aanvraag te bespreken.` }
      if (this.opdrachtStatus === 50 && !this.isPartnerOpdracht) return { noRotate: true, icon: '<i class="fal fa-users"></i>', titel: `Je bent er bijna klaar voor`, omschrijving: `Je hebt je intake gedeeld. Start de opdracht zodra je begint met werken.` }

      if (this.opdrachtStatus === 40 && this.isPartnerOpdracht && !this.selectedOpdracht.akkoord) return { noRotate: true, icon: '<i class="fal fa-users"></i>', titel: `Jouw aanbieding ligt nu bij de andere partij.`, omschrijving: `De opdrachtgever heeft jouw aanbieding ontvangen en zal de opdracht starten als hij/zij akkoord gaat met de voorwaarden.` }
      if (this.opdrachtStatus === 40 && this.isPartnerOpdracht && this.selectedOpdracht.akkoord) return { noRotate: true, icon: '<i class="fal fa-users"></i>', titel: `Gefeliciteerd!`, omschrijving: `De opdrachtgever heeft jouw aanbieding geaccepteerd en wilt de opdracht beginnen.` }

      if (this.opdrachtStatus === 60) return { icon: `<i class="fal fa-piggy-bank"></i>`, titel: `Deze opdracht is gestart, succes!`, omschrijving: `Deze opdracht is onderweg.` }
      return ``
    },
    // gearchiveerd() {
    //   },
    isOpdrachtGearchiveerd() {
      if (this.selectedOpdracht?.status && this.selectedOpdracht?.status[this.selectedOpdracht?.status?.length - 1].event < 10) {
        return this.selectedOpdracht.status[this.selectedOpdracht.status?.length - 1]
      }
      return false
      // if (this.selectedOpdracht.gearchiveerd) return true
      // return false
    },
    intakeGedaan() {
      if (this.selectedOpdracht.afhandeling.intakeTekenaar) return true
      return false
    },
    userId() {
      return this.$store.state.user.user.uid
    },
    selectedOpdracht() {
      return this.$store.getters.selectedOpdracht
    },
    opdrachtStatus() {
      return this.selectedOpdracht.status[this.selectedOpdracht.status.length - 1].event
    },
    opdrachtStatusNumber() {
      if (this.opdrachtStatus === 30) return 1
      if (this.opdrachtStatus === 40) return 2
      if (this.opdrachtStatus === 50) return 3
      if (this.opdrachtStatus === 60) return 4
      return 0
    },
  },
  methods: {
    triggerDynamicCTAFunction() {
      const status = this.opdrachtStatus
      if (status === 30) return (this.showAccepteerForm = true)
      if (status === 40) return (this.showIntakeForm = true)
      if (status === 50) return (this.showStartForm = true)
      if (status === 60) return (this.showFeedbackForm = true)
      return alert("Error: geen actie om uit te voeren gevonden")
    },
    closePopup() {
      this.$store.commit("setSelectedOpdrachtId", "")
    },
    accepteerOpdracht(data) {
      if (this.isPartnerOpdracht) {
        this.updateOpdrachtInDB({
          aanbieding: {
            uren: data.uren,
            uurprijs: 55,
          },
          status: firebase.firestore.FieldValue.arrayUnion({
            event: 40,
            timestamp: new Date(),
            userId: this.userId,
          }),
        })
      } else {
        this.updateOpdrachtInDB({
          status: firebase.firestore.FieldValue.arrayUnion({
            event: 40,
            timestamp: new Date(),
            userId: this.userId,
          }),
        })
      }
    },
    startOpdracht(e) {
      try {
        this.updateOpdrachtInDB({
          status: firebase.firestore.FieldValue.arrayUnion({
            event: 60,
            timestamp: new Date(),
            userId: this.userId,
            content: e,
          }),
        })

        db.collection(`opdracht_starts`).add({
          opdrachtgever: {
            naam: `${this.selectedOpdracht.opdrachtgever.naam.voornaam} ${this.selectedOpdracht.opdrachtgever.naam.achternaam}` || "",
            email: this.selectedOpdracht.opdrachtgever.email,
          },
          timestamp: new Date(),
          createdAt: new Date(),
          opdrachtNummer: this.selectedOpdracht.metadata.opdrachtNummer,
          tekenaar: {
            naam: this.userData.userProfile.voornaam,
            email: this.userData.userProfile.email,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    factureerOpdracht(e) {
      this.addNewFactuurToDB({
        ...e,

        adresTekenaarDetails: {
          straat: `${this.userData.userProfile.straat} ${this.userData.userProfile.huisnummer}`,
          postcode: this.userData.userProfile.postcode || "",
          plaats: this.userData.userProfile.plaats || "",
        },

        bedrijfsnaamTekenaar: this.userData.userProfile.bedrijfsnaam,
        volledigOpdrachtNummer: this.opdrachtNummerFormatted,
        bedrijfsnaamOpdrachtgever: `${this.selectedOpdracht.opdrachtgever.bedrijfsnaam || ""}`,
        naamOpdrachtgever: `${this.selectedOpdracht.opdrachtgever.naam.voornaam || ""} ${this.selectedOpdracht.opdrachtgever.naam.achternaam || ""}`,
        emailOpdrachtgever: `${this.selectedOpdracht.opdrachtgever.email}`,
        timestamp: new Date(),
        userId: this.userId,
        opdrachtId: this.selectedOpdracht.id,
      })
    },
    saveFeedbackOpdracht(e) {
      this.updateOpdrachtInDB({
        feedbackTekenaar: firebase.firestore.FieldValue.arrayUnion({
          interaction: "feedback",
          timestamp: new Date(),
          userId: this.userId,
          content: e,
        }),
      })
    },
    saveIntakeOpdracht(e) {
      this.updateOpdrachtInDB({
        "afhandeling.intakeTekenaar": { ...e, timestamp: new Date() },
        status: firebase.firestore.FieldValue.arrayUnion({
          event: 50,
          timestamp: new Date(),
          userId: this.userId,
          content: e,
        }),
      })
    },
    archiveerOpdracht(e) {
      this.updateOpdrachtInDB({
        status: firebase.firestore.FieldValue.arrayUnion({
          event: 0,
          timestamp: new Date(),
          userId: this.userId,
          approved: false,
          content: e,
        }),
      })
    },
    deArchiveerOpdracht() {
      const obj = [...this.selectedOpdracht.status]
        .slice()
        .reverse()
        .filter(e => e.event > 9)[0]

      this.updateOpdrachtInDB({
        status: firebase.firestore.FieldValue.arrayUnion({
          timestamp: new Date(),
          userId: this.userId,
          event: 8, // ongedaan maken?
        }),
      })
      this.updateOpdrachtInDB({
        status: firebase.firestore.FieldValue.arrayUnion({
          ...obj,
          timestamp: new Date(),
        }),
      })
    },
    addNewFactuurToDB(factuurData) {
      try {
        db.collection(`facturen-incoming`).add(factuurData)
        alert("Je gegevens zijn succesvol ontvangen. Zodra de mail met factuur naar de opdrachtgever is verstuurd verschijnt jouw factuur in het overzicht.")
      } catch (error) {
        console.log(error)
      }
    },
    updateOpdrachtInDB(update) {
      try {
        db.doc(`opdrachten/${this.selectedOpdracht.id}`).update(update)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="sass" scoped>








.archiveerBalk
  display: flex
  align-items: center
  > *
    transition: .25s !important
  &:hover
    cursor: pointer
    span
      background-color: red
  .line
    width: 100%
    margin-top: 1px
    height: 1px
    background-color: rgba(0, 0, 0, 0.08)


  .archiveerButton
    background-color: orange
    color: white !important

  span
    border-radius: 10000px
    padding: 6px 10px
    margin: 0 10px
    white-space: nowrap
    display: flex
    align-items: center
    font-size: 13px
    font-weight: 600
    color: rgba(0, 0, 0, 0.6)
    i
      font-size: 12px
      margin-top: 2px
      margin-right: 7px


@keyframes slideIn
  0%
    opacity: 0
    transform: translateY(200%)
  100%
    opacity: 1
    transform: translateY(0%)

.explanationWrapper
  position: relative
  min-height: 130px
.explanation
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: #072950 // kleur bij koppeling
  padding: 20px 20px 20px 14px
  color: white
  display: flex
  align-items: center
  overflow: hidden
  margin-bottom: 1px
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.1)
  border-radius: 10px 10px 0 0
  &.partnerBackground
    background-color: #DB5026 // kleur bij uitnodiging


  .iconHolder
    max-width: 70px
    min-width: 70px
    max-height: 70px
    min-height: 70px
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center
    font-size: 40px
    // background-color: rgba(0, 0, 0, 0.2)
    border: 2px solid white
    // background-color: #C3360C
    margin-right: 16px
    color: rgba(255, 255, 255, 1)
    transform: translateY(-10px) rotate(-16deg)
    transform: translateY(-10px)
    > div > i
      font-size: 43px !important

  > div
    h3
      animation: slideIn 0.7s 0.4s cubic-bezier(.54,.39,.44,1) both
    p
      animation: slideIn 1s 0.5s cubic-bezier(.54,.39,.44,1) both
      color: rgba(255, 255, 255, 0.7)
      font-size: 15px
      padding-top: 5px

  img
    width: 45px
    margin-right: 20px
    animation: slideIn 0.8s 0.1s cubic-bezier(.54,.39,.44,1) both

.slide-enter-active, .slide-leave-active
  transition: 0.8s ease
.slide-enter-from
  transform: translateX(120%)
.slide-leave-to
  transform: translateX(-120%)

.popin-enter-active, .popin-leave-active
  transition: 0.25s ease-out
.popin-enter-from, .popin-leave-to
  opacity: 0
  transform: translate(-50%, -50%) scale(0.85) !important

.fadein-enter-active, .fadein-leave-active
  transition: 0.25s ease
.fadein-enter-from, .fadein-leave-to
  opacity: 0


.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10000
  background-color: rgba(0, 0, 0, 0.5)

.opdrachtPopup
  background-color: #fff
  width: 80vw
  // min-height: 80vh
  max-width: 900px
  position: fixed
  z-index: 10001
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  overflow-y: scroll
  border-radius: 6px
  max-height: 95vh

  .logoAbsoluteWrapper
    // width: 100px
    padding: 10px 10px
    max-height: 30px
    object-fit: contain
    z-index: 100
    position: absolute
    top: 10px
    right: 60px
    background-color: white
    display: flex
    align-items: center
    justify-content: center

    border-radius: 10000px
    transition: .2s

    .tooltip
      transition: .2s
      transform: translateX(10px)
      opacity: 0
      pointer-events: none
      position: absolute
      top: 0px
      padding: 4px
      white-space: nowrap
      font-weight: 600
      font-size: 14px
      border-radius: 20px
      right: 110%
      color: black
      opacity: 1
      color: rgba(0, 0, 0, 0.4)
      font-style: italic
      transform: translateX(0px)

    img
      height: 20px

  .header
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid #f3f5f9
    padding: 0 0 0 15px
    position: sticky
    background-color: #fff
    z-index: 5
    top: 0



    .closeButton
      // background-color: #f3f5f9
      border-left: 1px solid #f3f5f9
      width: 50px
      height: 50px
      cursor: pointer
      transition: 0.3s
      color: rgba(0, 0, 0, 0.4)
      &:hover
        background-color: black
        color: white

  .contentWrapper
    padding: 20px 40px
    flex-grow: 1
    background-color: #f3f5f9
    position: relative

    .blurLayer
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      background-color: rgba(0, 0, 0, 0.2)
      z-index: 1
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      backdrop-filter: blur(5px)
      > div
        display: flex
        flex-direction: column
        align-items: center
        h1
          font-size: 24px
          max-width: 30ch
          text-align: center
        button
          background-color: blue
          color: white
          outline: none
          border: none
          border-radius: 5px
          padding: 0.75rem
          font-weight: 600
          font-family: 'manrope'
          font-size: 16px
          margin-top: 1rem



    .block
      // margin-bottom: 20px
      background-color: #fff
      width: 100%
      display: flex
      border-radius: 12px
      // flex-direction: column
      padding: 0
      border: 1px solid rgba(0, 0, 0, 0.08)
      &.facturen
        margin-top: 20px
        padding: 20px
        flex-direction: column
        border-radius: 10px 10px
        box-shadow: 0 20px 70px rgba(0, 0, 0, 0.1)
        z-index: 1
        position: relative
      h3
        padding-bottom: 10px

      .lineDivider
        width: 1px
        background-color: rgba(0, 0, 0, 0.1)
      .left
        padding: 0 20px
      .right
        padding: 0 20px
</style>

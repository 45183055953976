<template>
  <div class="notificationsWrapper">
    <div class="content">
      <h1>Teampagina</h1>
      <span v-if="newOpdrachten.length === 1"><i class="fal fa-bell"></i> {{ newOpdrachten.length }} Nieuw opdracht sinds je laatste bezoek</span>
      <span v-else-if="newOpdrachten.length > 1"><i class="fal fa-bell"></i> {{ newOpdrachten.length }} Nieuwe opdrachten sinds je laatste bezoek</span>
      <span style="color: rgba(0,0,0,0.4)" v-else>Er zijn geen nieuwe opdrachten sinds je laatste bezoek</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  computed: {
    opdrachten() {
      return this.$store.getters.opdrachten
    },
    newOpdrachten() {
      return this.opdrachten.filter(e => {
        let incomingDate = ""

        if (!e.aanvraagdatum) return

        if (e.aanvraagdatum[0] === `"`) {
          incomingDate = e.aanvraagdatum.substring(1, e.aanvraagdatum.length - 1)
        } else {
          incomingDate = e.aanvraagdatum
        }

        if (new Date(incomingDate).getTime() > this.$store.state.user.previousLogin?.getTime()) {
          return e
        }
      })
    },
    userData() {
      return this.$store.getters.userData
    },
  },
}
</script>

<style lang="sass" scoped>


.notificationsWrapper
  background-image: linear-gradient(0deg, rgba(243,245,249,1) 0%, rgba(243,245,249,0) 100%), url('../assets/images/tekenaars-hero.png')
  background-size: cover
  background-position: bottom
  animation: fadeUp30px .4s ease-out 0.5s both

  margin-bottom: -5vh
  padding: 10vh 5vw 20vh
  span
    opacity: 0.4
    font-size: 16px
    &:nth-of-type(1)
      color: orange
      opacity: 1
      display: flex
      align-items: center
      padding-top: 10px

      i
        margin-right: 8px
        width: 24px
        font-weight: 600
        border-radius: 10000px
        font-size: 14px
        padding-top: 2px
        padding-right: 1px

        display: flex
        align-items: center
        justify-content: center
        height: 24px
        background-color: orange
        color: white

  h1
    font-size: 3em




  .notificationWrapper
    position: relative
    display: flex
    display: flex
    width: 100%
</style>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'


@media only screen and (max-width: 1100px)
  .notification
    flex-direction: column-reverse
    justify-content: flex-start !important
    // background-color: green
    padding: 30px 30px 70px !important
    .emoji, .image
      max-height: 40px !important
      margin: 0 !important
      font-size: 60px !important
      margin-bottom: 30px !important


@media only screen and (max-width: 600px)
  .notificationsWrapper
    margin-bottom: 0
  .welcomeNotification
    padding: 0 20px
</style>

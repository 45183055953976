<template>
  <div class="wrapper">
    <div class="top" v-if="isPartnerOpdracht">
      <div class="cdt_note">
        <div class="left">
          <i class="fal fa-info-circle"></i>
          <span>Neem contact op met de opdrachtgever voordat je verder gaat.</span>
        </div>
        <div class="right">
          <i style="opacity: 0" class="fal fa-times-circle"></i>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <h3>Details</h3>
        <DetailItem v-if="selectedOpdracht.inkoopNummer" :tag="true" name="inkoopnummer" :value="selectedOpdracht.inkoopNummer" />
        <DetailItem v-if="opdrachtStatus === 30 && !isPartnerOpdracht" name="bedrijfsnaam" :value="`••••••••••`" />
        <DetailItem v-else name="bedrijfsnaam" :value="selectedOpdracht.opdrachtgever.bedrijfsnaam" />
        <DetailItem name="discipline" :value="selectedOpdracht.aanvraag.discipline" />
        <DetailItem name="werkplek" :value="selectedOpdracht.aanvraag.werkplek" />
        <DetailItem name="tekenprogramma" :value="selectedOpdracht.aanvraag.tekenprogramma" />
      </div>
      <div class="lineDivider"></div>
      <div class="right">
        <h3>Omschrijving</h3>
        <p class="omschrijving">{{ selectedOpdracht.aanvraag.omschrijving }}</p>
        <div v-if="opdrachtStatus === 30 && !isPartnerOpdracht">
          <h3>Contactgegevens</h3>
          <div><i class="fas fa-user"></i> ••••• ••••••</div>
          <div><i class="fas fa-envelope"></i> •••••••••••</div>
          <div><i class="fas fa-phone"></i> ••••••••••</div>
        </div>
        <div v-else>
          <a class="downloadFile" v-if="selectedOpdracht.aanvraag.voorbeeldBestandUrl" :href="selectedOpdracht.aanvraag.voorbeeldBestandUrl" target="_blank">
            <span><i class="fas fa-file-download"></i> Download aangeleverde documenten</span>
          </a>

          <div class="contactGegevens">
            <CopySlot v-if="`${selectedOpdracht.opdrachtgever.naam.voornaam} ${selectedOpdracht.opdrachtgever.naam.achternaam}`" :valueToCopy="`${selectedOpdracht.opdrachtgever.naam.voornaam} ${selectedOpdracht.opdrachtgever.naam.achternaam}`">
              <div class="blue"><i class="fas fa-user"></i> {{ `${selectedOpdracht.opdrachtgever.naam.voornaam} ${selectedOpdracht.opdrachtgever.naam.achternaam}` }}</div>
            </CopySlot>

            <div class="spacer"></div>
            <CopySlot :valueToCopy="selectedOpdracht.opdrachtgever.email">
              <div class="blue"><i class="fas fa-envelope"></i> {{ selectedOpdracht.opdrachtgever.email }}</div>
            </CopySlot>
            <div class="spacer"></div>
            <CopySlot v-if="selectedOpdracht.opdrachtgever.telefoonnummer" :valueToCopy="selectedOpdracht.opdrachtgever.telefoonnummer">
              <div class="blue"><i class="fas fa-phone"></i> {{ selectedOpdracht.opdrachtgever.telefoonnummer }}</div>
            </CopySlot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopySlot from "./CopySlot"
import DetailItem from "./DetailItem"
export default {
  props: ["selectedOpdracht", "opdrachtStatus", "isPartnerOpdracht"],
  components: { DetailItem, CopySlot },
}
</script>

<style lang="sass" scoped>


.downloadFile
  display: flex
  // margin-top: 20px
  font-size: 14px
  color: black
  text-decoration: none
  letter-spacing: 0.01em
  background-color: rgba(0, 0, 0, 0.04)
  align-items: center
  justify-content: center
  padding: 10px
  border-radius: 4px
  cursor: pointer
  border: 1px solid rgba(0, 0, 0, 0.032)
  transition: .25s
  &:hover
    background-color: rgba(0, 0, 0, 0.07)
    color: blue

  i
    margin-right: 6px
    color: blue




h3
  font-size: 16px
  // font-size: 20px
  font-weight: 600
  letter-spacing: 0.02em


.omschrijving
  color: rgba(7, 41, 80, 0.8)
  font-size: 20px
  padding-top: 0.6em
  padding-bottom: 1.5em
  line-height: 1.5em
  font-weight: 400
  letter-spacing: 0.02em

.wrapper
  display: flex
  flex-direction: column
  width: 100%
  .top
    width: 100%
    padding: 20px 20px 0
    display: flex
    flex-direction: column

    .cdt_note
      flex-grow: 1
      width: 100%
      .left
        i
          transform: translateY(1px)
        span
          transform: translateY(-2px)


  .bottom
    display: flex
    flex-direction: row


    .left, .right
      padding: 30px 0px


      // h3
        // padding: 0 30px
      > *
        padding-right: 25px
        padding-left: 25px


    .lineDivider
      width: 1px
      background-color: rgba(0, 0, 0, 0.1)

    .contactGegevens
      display: flex
      padding-top: 20px
      // padding: 10px
      // border-radius: 6px
      // border: 1px solid rgba(0, 0, 0, 0.08)

    .blue
      color: blue
      font-size: 14px
      font-weight: 400
      letter-spacing: 0.02em
      // padding: 6px 10px
      // background-color: rgba(0, 0, 0, 0.04)
      // border: 1px solid rgba(0, 0, 0, 0.12)
      max-width: fit-content
      border-radius: 10000px
      color: rgba(7, 41, 80, 0.8)
      i
        color: blue
        font-size: 14px
        margin-right: 6px
    .spacer
      width: 30px
</style>

<template>
  <div class="page">
    <div class="sidebar">
      <div class="logo">
        <img style="max-height: 32px" src="../assets/logo.png" />
        <span>CAD-Tekenaar.com</span>
      </div>
      <div class="actionWrapper">
        <div v-show="authMode === 'login'" class="message">
          <h4>Welkom terug</h4>
          <h2>Login op de teampagina</h2>
        </div>
        <div v-show="authMode === 'signup'" class="message">
          <h4>Welkom bij ons platform</h4>
          <h2>Maak een account om je aan te melden bij CAD-Tekenaar.com</h2>
        </div>
        <form @submit.prevent="submit()">
          <input autocomplete="email" type="email" v-model="email" required placeholder="Email" />
          <div class="inputDivider"></div>
          <input autocomplete="current-password" placeholder="Wachtwoord" type="password" v-model="password" required />
          <div class="inputDivider"></div>
          <button class="cdt_button" type="submit">
            <span v-show="authMode === 'login'">
              Login
            </span>
            <span v-show="authMode === 'signup'">Maak Account</span>
          </button>
        </form>
        <div class="formFooter">
          <!-- <a target="_blank" href="mailto:mail@cad-tekenaar.com?subject=Aansluiten bij CAD-Tekenaar.com&body=—-%0D%0A%0D%0ALeuk! Je wilt je graag aanmelden bij CAD-Tekenaar.com. Om je te kunnen inschrijven willen we graag eerst wat meer van je weten. Wil je in deze mail aangeven wie je bent, in welke vakgebied je werkzaam bent en waarom jij je wilt aanmelden? Wanneer wij je mail hebben ontvangen ontvang je van ons een link om je profiel aan te maken op onze teampagina%0D%0A%0D%0A--"> -->
          <router-link v-if="authMode !== 'signup'" to="/auth?authmode=signup">
            <span>Nog geen account?</span>
          </router-link>
          <!-- </a> -->
          <!-- <span @click="$router.push('/auth?authmode=signup')" v-if="authMode === 'login'">Nog geen account?</span> -->
          <span @click="$router.push('/auth')" v-if="authMode === 'signup'">Heb je al een account?</span>
          <!-- <span>Wachtwoord vergeten?</span> -->
        </div>
        <br />
        {{ errorDynamic }}
      </div>
      <span class="footer"><i class="fal fa-user-unlock"></i>Deze omgeving is alleen toegankelijk voor tekenaars van CAD-Tekenaar.com</span>
    </div>
    <div class="wallpaper">
      <div class="wrapper">
        <h1>Welkom bij de toekomst van zelfstandig werken</h1>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"
import { auth, db } from "../firebase"

// => gebruik voor testen parameters
// http://localhost:8080/auth?authmode=signup&email=vaneijkchris@gmail.com

export default {
  created() {
    this.email = this.sentInEmail
  },
  data() {
    return {
      view: "login",
      email: "",
      password: "",
      error: "",
    }
  },
  computed: {
    errorDynamic() {
      if (this.error === "auth/user-not-found") return "Je hebt nog geen account..."
      if (this.error === "auth/wrong-password") return "Je email of wachtwoord klopt niet..."
      if (this.error === "auth/weak-password") return "Verzin een langer wachtwoord..."

      return ""
    },
    authMode() {
      return this.$route.query.authmode || "login"
    },
    sentInEmail() {
      return this.$route.query.email ?? ""
    },
  },
  methods: {
    async submit() {
      this.$store.commit("setAuthLoader", true)
      if (this.authMode === "login") {
        await auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push("/")
            this.$store.commit("setAuthLoader", false)
          })
          .catch(error => {
            setTimeout(() => {
              this.$store.commit("setAuthLoader", false)
            }, 500)
            console.log(error)
            this.error = error.code
          })
      } else if (this.authMode === "signup") {
        try {
          const newUser = await auth.createUserWithEmailAndPassword(this.email, this.password)
          const userId = newUser.user.uid
          await db
            .collection("users")
            .doc(userId)
            .set({
              // email: this.email,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              accountVersion: 0.1,
              userType: "tekenaar",
              email: this.email,
              userProfile: {
                email: this.email,
              },
            })
          console.log("Yes! User aanmaken + doc gelukt!")
          this.$store.commit("setAuthLoader", false)
        } catch (error) {
          console.log(error)
          this.error = error.code

          this.$store.commit("setAuthLoader", false)
        }
      }
    },
    logout() {
      auth.signOut()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@keyframes slideIn
  0%
    opacity: 0
    transform: translateY(50px)
  100%
    opacity: auto
    transform: translateY(0)

.page
  min-height: 100vh
  max-height: 100vh
  display: flex
  background-color: black

.sidebar
  background-color: $black
  // padding: 30px
  color: white
  width: 40%
  min-width: 490px
  max-width: 500px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between

.actionWrapper
  padding: 0 30px
  border-radius: 24px
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  max-width: 75%
  .message
    width: 100%
    padding-bottom: 60px
    h4
      animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.1s 1 both
      opacity: .5
      padding-bottom: 10px
      font-weight: 500
    h2
      animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.2s 1 both

  h3
    margin-bottom: 24px
  form
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    > *
      width: 100%
    input
      animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.3s 1 both
      color: white
      font-weight: 700
      background-color: $black
      border-color: rgba(255, 255, 255, 0.3)
      // color: rgba(255, 255, 255, 0.4)
      &:focus
        border-color: $cdt_blue
      &::placeholder
        font-weight: 500
        color: $cdt_spanColor
        color: rgba(255, 255, 255, 0.4)
    button
      animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.4s 1 both
      &:focus
        filter: brightness(1.2)
    .inputDivider
      height: 20px
  .formFooter
    animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.5s 1 both
    padding-top: 20px
    display: flex
    justify-content: space-between
    width: 100%
    span
      &:first-child
        color: $cdt_spanColor
      color: $cdt_blue
      font-size: 14px
      white-space: nowrap
      cursor: pointer

.logo
  .loader
    max-height: 64px
  animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.0s 1 both
  padding: 20px
  padding-bottom: 0
  width: 100%
  display: flex
  align-items: center
  img
    // height: 32px
    // border-radius: 10000px
  span
    // opacity: 0
    padding-left: 10px
    color: white
    font-size: 14px
    font-weight: 700
    letter-spacing: 0.04em
.footer
  animation: slideIn 0.5s cubic-bezier(.74,.2,.51,.82) 0.6s 1 both
  border-top: 1px solid rgba(255, 255, 255, 0.3)
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 20px 14px 20px
  font-size: 12px
  font-weight: 600
  opacity: 0.75
  color: rgba(255, 255, 255, 0.5)
  i
    font-weight: 600
    margin-right: 10px
.wallpaper
  animation: slideIn 0.6s ease-out 1s 1 both
  flex-grow: 1
  background-color: $cdt_background_grey
  background: url('../assets/images/background.jpg')
  background-position: center
  background-size: cover
  color: white
  padding: 40px
  display: flex
  flex-direction: column-reverse
  align-items: flex-end
  align-items: flex-end
  position: relative

  .wrapper
    background-color: rgba(0, 0, 0, 0.5)
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: flex-end
    justify-content: flex-end
    padding: 40px
    text-align: right
  h1
    animation: slideIn 0.9s ease-out 1.1s 1 both
    font-size: 24px
    max-width: 20ch
    text-align: right
    font-weight: 500


@media only screen and (max-width: 600px)
  .wallpaper
    display: none
  .page
    min-height: calc( 100vh - env(safe-area-bottom-inset) )
    max-height: calc( 100vh - env(safe-area-bottom-inset) )
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%

  body
    background-color: black !important

    .sidebar
      width: 100%
      max-width: none
      min-width: 0px

      .actionWrapper
        padding: 0 20px
        max-width: none

    .footer
      i
        display: none
      font-size: 10px
</style>

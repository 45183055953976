<template>
  <div class="blackFiller" @click="closeForm()"></div>

  <div class="startForm">
    <div class="header">
      <span>Start formulier</span>
      <div @click="closeForm()" class="closeButton">X</div>
    </div>
    <div class="content">
      <div>
        <label>Startdatum</label>
        <input v-model="startData.startDatum" placeholder="Startdatum" type="date" />
      </div>
      <div>
        <label>Verwacht einddatum</label>
        <input v-model="startData.verwachtEindDatum" placeholder="Verwacht einddatum" type="date" />
      </div>
      <div>
        <label>Korte omschrijving</label>
        <textarea v-model="startData.korteOmschrijving" placeholder="Korte omschrijving"></textarea>
      </div>
      <div>
        <label>Upload offerte/opdrachtbevestiging</label>
        <Uploader @fileUrl="startData.opdrachtBevestigingUrl = $event" :storageRefMap="`opdrachtBevestigingenVanTekenaars`" />
      </div>
      <div v-if="!isPartnerOpdracht">
        <div>
          <label>Welke prijsafspraak heb je gemaakt?</label>
          <div class="options">
            <div class="option" @click="startData.prijsAfspraak = 'regie'" :class="{ active: startData.prijsAfspraak === 'regie' }"><i class="fas fa-check-circle"></i> Regie-basis</div>
            <div class="option" @click="startData.prijsAfspraak = 'vastePrijs'" :class="{ active: startData.prijsAfspraak === 'vastePrijs' }"><i class="fas fa-check-circle"></i> Vaste prijs</div>
          </div>
        </div>
        <div>
          <label v-if="startData.prijsAfspraak === 'regie'">Uurprijs (€)</label>
          <label v-if="startData.prijsAfspraak === 'vastePrijs'">Geoffreerd bedrag (€)</label>
          <input v-model="startData.prijs" placeholder="€100,00" type="number" />
        </div>
      </div>
      <div v-else>
        <label>Uurprijs (€{{ startData.prijs }}) - vast bij partneropdrachten</label>
      </div>
      <div v-if="startData.prijsAfspraak === 'regie' || isPartnerOpdracht">
        <label>Schatting aantal uren</label>
        <input v-model="startData.urenSchatting" placeholder="20" type="number" />
      </div>
      <br />
      <button :class="{ clickable: formCompleted }" @click="submit()"><i class="fas fa-play-circle"></i> Start deze opdracht</button>
    </div>
  </div>
</template>

<script>
import Uploader from "../Uploader"
export default {
  name: "startFormComponent",
  components: { Uploader },
  props: ["isPartnerOpdracht"],
  created() {
    if (this.isPartnerOpdracht) this.startData.prijs = 55
  },
  data() {
    return {
      startData: {
        startDatum: "",
        verwachtEindDatum: "",
        korteOmschrijving: "",
        opdrachtBevestigingUrl: "",
        prijsAfspraak: "regie",
        prijs: "",
        urenSchatting: "",
      },
    }
  },
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      const e = this.startData
      if (!this.formCompleted) return alert("Vul alle velden in aub")
      this.$emit("submit", { ...e, startDatum: new Date(e.startDatum), verwachtEindDatum: new Date(e.verwachtEindDatum) })
      this.closeForm()
    },
  },
  computed: {
    formCompleted() {
      const e = this.startData
      if (!e.startDatum || !e.verwachtEindDatum || !e.korteOmschrijving || !e.prijs || !e.prijsAfspraak) return false
      return true
    },
  },
}
</script>

<style lang="sass" scoped>


.options
  border: 1px solid rgba(0, 0, 0, 0.1)
  border-bottom: none

.option
  text-transform: capitalize
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  padding: 10px
  overflow: hidden
  font-size: 14px
  cursor: pointer
  position: relative
  transition: .25s
  i
    transition: .25s
    color: #62E887
    transform: translateX(-27px)
    position: absolute
    top: 14px
    left: 10px
    margin-right: 4px
  &.active
    font-weight: 600
    background-color: rgba(0, 0, 0, 0.04)
    padding-left: 30px
    i
      transform: translateX(0)


.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.5)


.startForm
  overflow-y: scroll
  max-height: 95vh
  position: fixed
  z-index: 10002
  background-color: #fff
  box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 10px
  display: flex
  flex-direction: column
  .header
    display: flex
    padding: 20px
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  .content
    padding: 0 20px 20px 20px
    display: flex
    flex-direction: column
    > div
      display: flex
      flex-direction: column
      padding-top: 15px

  button
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    opacity: 0.4
    pointer-events: none
    &.clickable
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { log } from "@/helpers"

log("==========================")
log("⏳ BOOTING UP APPLICATION")
log("==========================")

store.dispatch("startFirebase")

createApp(App)
  .use(store)
  .use(router)
  .mount("#app")

<template>
  <div class="blackFiller" @click="closeForm()"></div>

  <div class="startForm">
    <div class="header">
      <span>Bevestig samenwerking</span>
      <div @click="closeForm()" class="closeButton">X</div>
    </div>
    <div class="content">
      <div>
        <h3>Met deze bevestiging:</h3>
        <ul>
          <li>
            <i class="fas fa-check-circle"></i>
            <span> Houdt je CAD-Tekenaar.com minimaal 1x per 2 weken op hoogte van de voortang. </span>
          </li>
          <li>
            <i class="fas fa-check-circle"></i>
            <span> Werk je netjes en volgens afspraken met de opdrachtgever. </span>
          </li>
          <li>
            <i class="fas fa-check-circle"></i>
            <span> Zorg je dat het werk conform afspraken gedaan wordt en ben je een visitekaartje voor jezelf en CAD-Tekenaar.com. </span>
          </li>
          <li>
            <i class="fas fa-check-circle"></i>
            <span> Draag je zorg voor een goede start en afmelding van je project via de teampagina. </span>
          </li>
        </ul>
        <div v-if="!isPartnerOpdracht" class="scrollableVoorwaarden">
          <span><i class="fas fa-check-circle"></i> Voorwaarden</span>
          <p>Voor de volledige Algemene Voorwaarden en ondersteuning verwijzen we naar https://support.cad-tekenaar.com</p>
        </div>
        <div v-if="isPartnerOpdracht" class="scrollableVoorwaarden">
          <!-- <span><i class="fas fa-check-circle"></i> Let op</span> -->
          <span>Let op!</span>
          <p>
            <!-- <strong>Let op!</strong> -->
            <br />
            Onderstaand wordt je gevraagd om een reële inschatting te maken van de benodigde uren voor het succesvol uitvoeren van deze opdracht. Bereidt deze inschatting goed voor. Ga bijvoorbeeld in overleg met de opdrachtgever, vraag de juiste tekeningen op om je voor te bereiden of beoordeel het aantal uren op basis van eerder uitgevoerd werk. Het aantal ingeschatte uren wordt beoordeeld door de opdrachtgever en je krijgt op basis van deze inschatting een akkoord.
          </p>
        </div>
      </div>

      <br v-if="isPartnerOpdracht" />
      <input v-if="isPartnerOpdracht" type="number" v-model="uren" placeholder="Hoeveel uur schat je nodig te hebben?" />
      <br />
      <button v-if="!isPartnerOpdracht" :class="{ clickable: formCompleted }" @click="submit()"><i class="fas fa-unlock"></i> Accepteer voorwaarden en opdracht</button>
      <button v-if="isPartnerOpdracht" :class="{ clickable: formCompleted }" @click="submit()"><i class="fas fa-unlock"></i> Bevestig samenwerking en verstuur uren</button>
    </div>
  </div>
</template>

<script>
// import Uploader from "../Uploader"
export default {
  // components: { Uploader },
  data() {
    return {
      uren: null,
    }
  },
  props: ["isPartnerOpdracht"],
  emits: ["close", "submit"],
  methods: {
    closeForm() {
      this.$emit("close")
    },
    submit() {
      if (this.isPartnerOpdracht && !this.uren) return
      this.$emit("submit", { uren: this.uren })
      this.closeForm()
    },
  },
  computed: {
    formCompleted() {
      return true
    },
  },
}
</script>

<style lang="sass" scoped>

.scrollableVoorwaarden
  background-color: rgba(0, 0, 0, 0.04)
  max-height: 20vh
  overflow-y: scroll
  padding: 20px 20px
  font-size: 13px
  margin-top: 20px
  max-width: 52ch
  color: rgba(0, 0, 0, 0.7)
  span
    display: inline-block
    transform: translateX(-5px)
    margin-bottom: 10px
    background-color: rgba(0, 0, 0, 0.1)
    padding: 6px
    border-radius: 10000px
    text-align: center
    font-weight: 800



ul
  display: flex
  flex-direction: column
  li
    padding-top: 16px
    list-style: none
    font-size: 13px
    max-width: 50ch
    display: flex
    align-items: flex-start
    i
      padding-top: 4px
      color: #62E887
      margin-right: 6px
      font-size: 16px
    span
      font-size: 14px

.blackFiller
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002
  background-color: rgba(0, 0, 0, 0.5)


.startForm
  position: fixed
  z-index: 10002
  background-color: #fff
  box-shadow: 0 30px 1000px 1000px rgba(0, 0, 0, 0.3)
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 10px
  display: flex
  flex-direction: column
  .header
    display: flex
    padding: 20px
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  .content
    padding: 0 20px 20px 20px
    display: flex
    flex-direction: column
    > div
      display: flex
      flex-direction: column
      padding-top: 15px

  button
    background-color: blue
    color: white
    border: none
    outline: none
    padding: 15px
    border-radius: 6px
    font-size: 16px
    font-weight: 700
    cursor: pointer
    opacity: 0.4
    pointer-events: none
    &.clickable
      pointer-events: all
      opacity: 1
      &:hover
        opacity: .8
</style>

<template>
  <div class="uploader">
    <input ref="imageUploadInput" type="file" :accept="accept" @change="startUpload()" style="display: none" />

    <div v-if="!voorbeeldBestand" class="summaryWrapper" @click="openSelector()">+ {{ text || "Upload bestand" }}</div>
    <div v-else class="summaryWrapper" @click="openSelector()"><i style="margin-right: 15px;" class="fal fa-pencil-alt"></i> {{ bestandsnaam }} is geupload</div>
    <!-- <div v-else class="summaryWrapper finished" @click="openSelector()">
      {{ bestandsnaam }} is geupload
      <br />
      <span style="font-size:12px; color: blue;">
        Klik om een ander bestand te kiezen
      </span>
    </div> -->
  </div>
</template>

<script>
import { storage } from "@/firebase"
export default {
  props: ["storageRefMap", "onlyAcceptImages", "text"],
  data() {
    return {
      imageData: null,
      imageURL: "",
      uploadProgress: 0,
      bestandsnaam: "",
      voorbeeldBestand: null,
    }
  },
  computed: {
    accept() {
      if (this.onlyAcceptImages) {
        return "image/jpeg,image/gif,image/png"
      } else {
        return "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
      }
    },
  },
  methods: {
    openSelector() {
      this.$refs.imageUploadInput.click()
    },

    startUpload() {
      this.imageData = null
      this.imageURL = ""
      this.uploadProgress = 0
      if (this.$refs.imageUploadInput.files[0].size / 1000000 > 5) return alert("Dit bestand is te groot. Comprimeer het of kies een ander bestand om te uploaden.")
      this.imageData = this.$refs.imageUploadInput.files[0]
      this.uploadeFileToFirebase()
    },

    uploadeFileToFirebase() {
      const storageRef = storage.ref(`${this.storageRefMap}/${this.imageData.name}`)
      const uploadProcess = storageRef.put(this.imageData)
      // Progress
      uploadProcess.on(
        "state_changed",
        snapshot => {
          this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          alert(error)
        },
        () => {
          this.bestandsnaam = this.imageData.name
          this.uploadProgress = 100
          uploadProcess.snapshot.ref.getDownloadURL().then(url => {
            this.voorbeeldBestand = url
            this.$emit("fileUrl", url)
          })
        }
      )
    },
  },
}
</script>

<style lang="sass" scoped>

.uploader
  background-color: #F3F5F9
  border: 1px solid rgba(0, 0, 0, 0.1)
  border-radius: 6px
  padding: 10px
  font-size: 14px
  text-align: center
  cursor: pointer
  &:hover
    opacity: 0.7
</style>

<template>
  <div class="TimestampWrapper">
    <div class="line"></div>
    <div class="stamp" :class="{ isGreen: isGreen }">{{ stamp }}</div>
    <div class="line" v-if="lineDown"></div>
  </div>
</template>

<script>
export default {
  name: "Timestamp",
  props: ["stamp", "lineDown", "isGreen"],
}
</script>

<style lang="sass" scoped>

.TimestampWrapper
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  font-size: 13px
  font-weight: 600
  .stamp
    background-color: rgba(0, 0, 0, 0.08)
    color: rgba(0, 0, 0, 0.4)
    padding: 6px 10px
    border-radius: 1000px
    &.isGreen
      background-color: #D1F1DF
      color: #1FBC61
  .line
    height: 30px
    width: 3px
    border-left: 2px dotted rgba(0, 0, 0, 0.2)
</style>

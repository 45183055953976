<template>
  <div class="DetailItem">
    <span class="value" :class="{ tag: tag }">{{ value }}</span>
    <span class="name">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: "DetailItem",
  props: ["value", "name", "tag"],
}
</script>

<style lang="sass" scoped>
.DetailItem
  display: flex
  flex-direction: column
  align-items: flex-start
  border-top: 1px solid rgba(0,0,0,0.1)
  padding: 10px 0
  letter-spacing: 0.03em
  cursor: pointer
  transition: .2s
  &:first-of-type
    margin-top: 20px
  &:last-child
    border-bottom: 1px solid rgba(0,0,0,0.1)
  .value
    white-space: nowrap
    font-size: 14px
    font-weight: 600
    &.tag
      color: orange
  .name
    font-size: 12px
    font-weight: 400
    color: rgba(7, 41, 80, 0.4)
</style>

export const onboarding = {
  state: {
    // Currently active step
    activeStep: 0,
    loader: false,

    // All steps
    steps: [
      {
        id: 1,
        title: "NAW gegevens",
        description: "Persoonlijke informatie"
      },

      //
      // subtasks of step 1
      // {
      //   id: 1.1,
      //   title: "doe iets",
      //   description: "testdinng",
      //   parent: 1,
      // },
      // {
      //   id: 1.2,
      //   title: "doe nog iets",
      //   description: "testdinng",
      //   parent: 1,
      // },
      //

      {
        id: 2,
        title: "Bedrijfsgegevens",
        description: "Bedrijfsgegevens"
      },
      {
        id: 3,
        title: "Jouw tekenaarsprofiel",
        description: "Jouw tekenaarsprofiel"
      },
      {
        id: 4,
        title: "Opslaan & Insturen",
        description: "Sla je account op"
      }
    ]
  },
  mutations: {
    increment(state) {
      state.activeStep++;
    },
    previousStep(state) {
      state.activeStep--;
    },
    toggleLoader(state) {
      state.loader = !state.loader;
    }
  },
  actions: {}
};

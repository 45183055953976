<template>
  <div class="cc">
    <div class="body flex1">
      <div class="leftWrapper">
        <div class="profile">
          <div class="header ">
            <div>
              <div class="profilePicture" :style="profilePicture"></div>
            </div>
            <div>
              <h4>{{ `${userData?.userProfile?.voornaam || "Voornaam"} ${userData?.userProfile?.achternaam || "Achternaam"}` }}</h4>
              <div profilePictureSelectWrapper>
                <span @click="openSelector()" class="profilePictureSelector">Pas profielfoto aan <i v-if="!profilePictureCheck" style="color: #EC2A39; margin-left:4px" class="fas fa-exclamation-circle flexCenter red"></i></span>
              </div>
              <input ref="imageUploadInput" type="file" accept="image/png, image/gif, image/jpeg" @change="startUpload()" style="display: none" />
            </div>
          </div>
          <div class="body">
            <div @click="activeCategory = 'naw'">
              <div class="item flex1" :class="{ active: activeCategory === 'naw' }">
                <div class="left flex1">
                  <i class="fad fa-user-edit flexCenter"></i>
                  <span> NAW Gegevens</span>
                </div>
                <div class="right">
                  <i v-if="nawGegevensComplete" class="fas fa-check-circle flexCenter green"></i>
                  <i v-if="!nawGegevensComplete" class="fas fa-exclamation-circle flexCenter red"></i>
                </div>
              </div>
            </div>
            <div @click="activeCategory = 'bedrijfsgegevens'">
              <div class="item flex1" :class="{ active: activeCategory === 'bedrijfsgegevens' }">
                <div class="left flex1">
                  <i class="fad fa-building flexCenter"></i>
                  <span>
                    Bedrijfsgegevens
                  </span>
                </div>
                <div class="right">
                  <i v-if="bedrijfsGegevensComplete" class="fas fa-check-circle flexCenter green"></i>
                  <i v-if="!bedrijfsGegevensComplete" class="fas fa-exclamation-circle flexCenter red"></i>
                </div>
              </div>
            </div>
            <div @click="activeCategory = 'werkprofiel'">
              <div class="item flex1" :class="{ active: activeCategory === 'werkprofiel' }">
                <div class="left flex1">
                  <i class="fad fa-address-card flexCenter"></i>
                  <span> Werkprofiel </span>
                </div>
                <div class="right">
                  <i v-if="werkProfielComplete" class="fas fa-check-circle flexCenter green"></i>
                  <i v-if="!werkProfielComplete" class="fas fa-exclamation-circle flexCenter red"></i>
                </div>
              </div>
            </div>
            <div @click="activeCategory = 'voorkeuren'">
              <div class="item flex1" :class="{ active: activeCategory === 'voorkeuren' }">
                <div class="left flex1">
                  <i class="fad fa-address-card flexCenter"></i>
                  <span>
                    Voorkeuren
                  </span>
                </div>
                <div class="right">
                  <i v-if="voorkeurenComplete" class="fas fa-check-circle flexCenter green"></i>
                  <i v-if="!voorkeurenComplete" class="fas fa-exclamation-circle flexCenter red"></i>
                </div>
              </div>
            </div>
            <div @click="activeCategory = 'kwaliteiten'">
              <div class="item flex1" :class="{ active: activeCategory === 'kwaliteiten' }">
                <div class="left flex1">
                  <i class="fad fa-stars flexCenter"></i>
                  <span>
                    Kwaliteiten
                  </span>
                </div>
                <div class="right">
                  <i v-if="kwaliteitenComplete" class="fas fa-check-circle flexCenter green"></i>
                  <i v-if="!kwaliteitenComplete" class="fas fa-exclamation-circle flexCenter red"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="beschikbaarheid" v-if="formData.userProfile">
          <h2>Beschikbaarheid <i v-if="!formData.userProfile.beschikbaar" style="color:red;font-size:16px;margin-left:10px;transform:translateY(-1px)" class="fas fa-exclamation-circle"></i></h2>
          <div class="beschikbaar" @click="toggleBeschikbaarheid()">
            <span>Ik ben beschikbaar</span>
            <input v-model="formData.userProfile.beschikbaar" type="checkbox" class="checkbox" />
          </div>
          <br />
          <div>
            <div class="beschikbaar twee" :class="{ inactive: !formData.userProfile.beschikbaar }">
              <h5>{{ formData.userProfile.beschikbaarheidInUrenPerWeek }} uur per week</h5>
              <input @change="updateBeschikbaarheid()" class="slider" type="range" min="1" max="40" step="0" v-model="formData.userProfile.beschikbaarheidInUrenPerWeek" />
            </div>
          </div>
        </div>
        <span v-if="userData && userData.lastUpdate" class="saveTimestamp"> Opgeslagen: {{ userData.lastUpdate.toDate().toLocaleString() }} </span>
      </div>

      <!-- 
        divider
       -->
      <div class="divider"></div>
      <!-- 
        divider
       -->
      <div class="actions">
        <UserProfile />
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "@/firebase"
import UserProfile from "@/components/UserProfile"

export default {
  components: { UserProfile },
  data() {
    return {
      beschikbaarheid: 40,
      imageData: null,
      uploadProgress: 0,
      imageURL: "",
    }
  },
  methods: {
    updateBeschikbaarheid() {
      this.$store.dispatch("debounceUpdateUserProfile")
    },
    toggleBeschikbaarheid() {
      this.formData.userProfile.beschikbaar = !this.formData.userProfile.beschikbaar
      this.$store.dispatch("debounceUpdateUserProfile")
    },

    openSelector() {
      this.$refs.imageUploadInput.click()
    },

    startUpload() {
      this.imageData = null
      this.imageURL = ""
      this.uploadProgress = 0
      if (this.$refs.imageUploadInput.files[0].size / 1000000 > 5) return alert("Kies een afbeelding die minder dan 5mb groot is")
      this.imageData = this.$refs.imageUploadInput.files[0]
      this.uploadImageToFirebase()
    },

    uploadImageToFirebase() {
      const storageRef = storage.ref(`profilePictures/${this.imageData.name}`)
      const uploadProcess = storageRef.put(this.imageData)
      // Progress
      uploadProcess.on(
        "state_changed",
        snapshot => {
          this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          alert(error)
        },
        () => {
          this.uploadProgress = 100
          uploadProcess.snapshot.ref.getDownloadURL().then(url => {
            this.$store.dispatch("setProfilePictureURL", url)
            // this.imageURL = url
          })
        }
      )
    },
  },
  computed: {
    notice() {
      if (!this.userData?.userProfile) return true
      const e = this.userData?.userProfile || {}
      if (e.aantalJaarErvaring && e.aantalJaarZelfstandig && e.achternaam && e.voornaam && e.ambities && e.bedrijfsnaam && e.disciplines.length > 0 && e.geboortedatum && e.hoogstGenotenOpleiding && e.huisnummer && e.iban && e.kvknummer && e.plaats && e.postcode && e.profilePictureURL && e.straat && e.tekenprogrammas.length > 0 && e.telefoon && e.voorkeurTypeOpdrachten && e.website && e.werkgebied.length > 0 && e.werkplek.length > 0) {
        return false
      } else {
        return true
      }
    },
    profilePictureCheck() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      if (e.profilePictureURL) return true
      return false
    },
    nawGegevensComplete() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      if (e.voornaam && e.achternaam && e.geboortedatum && e.telefoon && e.hoogstGenotenOpleiding && e.aantalJaarErvaring && e.straat && e.huisnummer && e.postcode) return true
      return false
    },
    bedrijfsGegevensComplete() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      if (e.bedrijfsnaam && e.kvknummer && e.iban && e.website && e.aantalJaarZelfstandig) return true
      return false
    },
    werkProfielComplete() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      if (e.disciplines?.length > 0 && e.tekenprogrammas?.length > 0) return true
      return false
    },
    voorkeurenComplete() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      if (e.werkgebied?.length > 0 && e.werkplek?.length > 0 && e.voorkeurTypeOpdrachten?.length > 0 && e.ambities) return true
      return false
    },
    kwaliteitenComplete() {
      if (!this.userData) return false
      const e = this.userData?.userProfile || {}
      if (!e) return false
      // if (e.kwaliteiten?.length > 0 && e.werkplek?.length > 0 && e.voorkeurTypeOpdrachten?.length > 0 && e.ambities) return true
      if (e.kwaliteiten?.length > 0) return true
      return false
    },
    activeCategory: {
      get() {
        return this.$store.state.userProfile.activeCategory
      },
      set(e) {
        this.$store.commit("setActiveCategory", e)
      },
    },
    userData() {
      return this.$store.getters.userData
    },
    profilePicture() {
      if (!this.userData.userProfile?.profilePictureURL) return `background-image: url("https://www.povertyalliance.org/wp-content/uploads/2019/03/Portrait_Placeholder.png")`
      return `background-image: url("${this.userData.userProfile.profilePictureURL}")`
    },
    formData: {
      get() {
        return this.$store.state.userProfile.formData
      },
      set(updatedFormData) {
        this.$store.commit("updateFormData", {
          ...updatedFormData,
        })
      },
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

.saveTimestamp
  opacity: .3
  font-size: 13px
  text-align: center
  width: 100%
  padding-top: 6px
  padding-left: 4px

.beschikbaar
  cursor: pointer
  margin-top: 20px
  display: flex
  align-items: center
  justify-content: space-between
  border: 2px solid $cdt_background_grey
  padding: 0 14px
  border-radius: 4px
  span
    font-size: 14px

  &.twee
    margin-top: 0px
    flex-direction: column
    padding: 14px 14px 0
    h5
      font-size: 20px
      font-weight: 700 !important
      white-space: nowrap
      width: 100%
    &.inactive
      pointer-events: none
      filter: blur(1px)
      opacity: 0.5 !important

.slider
  width: 100%

.profilePictureSelector
  cursor: pointer

.beschikbaarheid
  h2
    font-size: 18px

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number]
  -moz-appearance: textfield

.slider
  -webkit-appearance: auto

    height: 2px
    width: 100%
    background-color: green
</style>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'
@import '@/utilities/keyframes.sass'


a
  text-decoration: none
  color: inherit
  &:active
    color: green

//
.cc
  padding: 40px
  background-color: $cdt_background_grey


  .headerSection
    padding-bottom: 40px
    animation: fadeUp30px .4s ease-out 0.5s both
    // animation: fadeUp30px .4s ease-out 0s both


  .body
    align-items: flex-start
    // align-items: stretch

    .divider
      width: 40px


    .actions
      animation: fadeUp30px .4s ease-out 0.7s both
      // animation: fadeUp30px .4s ease-out 0.2s both
      flex-grow: 1
      border-radius: 8px
      // padding: 24px 0 100px
      // border: 2px solid $cdt_background_grey


    .leftWrapper
      position: sticky
      top: 40px
      animation: fadeUp30px .4s ease-out 0.6s both
      display: flex
      flex-direction: column

      .beschikbaarheid
        background-color: white
        padding: 20px
        border-radius: 4px
        margin-top: 20px


      .profile
        background-color: white
        // animation: fadeUp30px .4s ease-out 0.1s both
        display: flex
        flex-direction: column
        border-radius: 8px
        padding: 24px 0
        border: 2px solid $cdt_background_grey


        .header
          display: flex
          align-items: flex-start
          justify-content: flex-start
          text-align: left !important
          align-items: flex-start !important
          padding: 0 44px 24px 20px
          h4
            max-width: 20ch
            overflow: hidden
            white-space: nowrap
          > div
            &:first-child
              margin-right: 10px
          span
            margin: 0
            padding: 0
            color: $cdt_blue
            // text-decoration: underline
            font-size: 12px
            display: flex
            // transform: translateY(-3px)
          .profilePicture
            // padding: 60px
            width: 36px
            height: 36px
            background-size: cover
            background-position: center
            background-color: black
            border-radius: 1000000px


        .body
          .item
            &:first-child
              border-top: 2px solid $cdt_background_grey
            overflow: hidden
            position: relative
            cursor: pointer
            padding: 20px 24px
            // border-bottom: 2px solid $cdt_background_grey
            // color: $cdt_blue
            // color: $black
            font-weight: 600
            transition: .2s
            &:hover
              background-color: $cdt_blue_003

            &.active
              background-color: $cdt_blue
              background-color: black
              color: white
            i
              width: 20px
              font-size: 14px
              &.green
                color: $cdt_green
              &.red
                color: $cdt_red
            span
              transition: .2s
              padding-left: 8px
              // color: $cdt_spanColor
              font-size: 15px



@media only screen and (max-width: 600px)

  .componentWrapper
    width: 100% !important
  .cc
    padding: 0px
    max-width: 100%
    overflow-x: hidden
    padding-bottom: 40px

    .headerSection
      padding: 40px 20px 0px

    .body
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start


      .profile
        background-color: rgba(0, 0, 0, 0) !important
        padding: 0px 10px
        flex-direction: row
        border: none
        max-width: 100vw

        .header
          display: none
        .body
          margin-left: -3px
          // border-bottom: 1px solid black
          border-top: 1px solid black
          max-width: 100vw
          overflow-x: scroll
          display: flex
          flex-direction: row
          i
            display: none
          span
            white-space: nowrap
          .item
            // border-left: 2px solid black
            // border-right: 2px solid black
            &:first-child
              border-top: none !important
            margin-right: -1px
            border-radius: 0
            border: 1px solid black !important
            &:last-child
              padding-right: 30px

    .actions
      width: 100% !important
      // opacity: 0 !important
      padding-top: 50px
</style>
